<template>
  <b-col class="flex-direction-column">
    <div v-if="error" class="centered">
      <h2>Something went wrong...</h2>
      <code>{{ error }}</code>
    </div>
    <div v-else-if="initializing" class="centered">
      <h2>Loading...</h2>
    </div>
    <div v-else>
      <b-row>
        <b-col class="mb-2 d-flex justify-content-between align-content-center align-items-center">
          <span class="text-muted">Total: {{ users.length }}</span>
          <b-button-group>
            <b-button variant="outline-success" @click="openNewFtpUserModal">New User</b-button>
          </b-button-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-for="user in users" :key="user.userName" sm="12" class="mb-2">
          <div class="server-card">
            <div class="server-card-navigation-wrapper">
              <div class="server-name text-truncate">
                <span class="h4">{{ user.userName }}</span>
              </div>
              <div>
                <b-button variant="outline-warning" class="ml-2" @click="() => openEditFtpUserModal(user)">Edit</b-button>
                <b-button variant="outline-danger" class="ml-2"
                  @click="() => deleteFtpUser(user.userName)">Delete</b-button>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-modal id="ftp-user-modal" :title="modalTitle" size="lg" centered no-close-on-backdrop hide-header-close
      no-close-on-esc>
      <b-alert variant="danger" :show="formError">{{ formError }}</b-alert>
      <b-form-group label="Username:" label-for="userName">
        <b-form-input :disabled="!allowUserNameEdit" id="userName" v-model="form.userName" :state="userNameValid"
          required />
        <b-form-invalid-feedback :state="userNameValid">A username is required.</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Password:" label-for="password">
        <b-form-input id="password" v-model="form.password" :state="passwordValid" required />
        <b-form-invalid-feedback :state="passwordValid">A password is required.</b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Forbidden Paths:" label-for="forbiddenPaths"
        description="Enter each forbidden path on a new line">
        <b-form-textarea rows="4" id="forbiddenPaths" v-model="form.forbiddenPaths" />
      </b-form-group>
      <template #modal-footer="{ ok, cancel }">
        <b-button variant="danger" @click="cancel" :disabled="isSaving">Cancel</b-button>
        <b-button variant="success" @click="() => modalAction(ok)" :disabled="!canFormSave">Save</b-button>
      </template>
    </b-modal>

    <b-modal id="delete-confirm" size="sm" title="Confirm Deletion" centered no-close-on-backdrop hide-header-close
      no-close-on-esc>
      <span>Are you sure you want to continue?</span>
      <template #modal-footer="{ ok, cancel }">
        <b-button variant="primary" @click="cancel" :disabled="isDeleting">Cancel</b-button>
        <b-button variant="danger" @click="() => confirmDelete(ok)" :disabled="isDeleting">Delete</b-button>
      </template>
    </b-modal>
  </b-col>
  <!-- 
  <b-col lg="12" class="mx-auto d-flex flex-column">
    
    <b-card style="background: transparent; border: 1px solid rgba(255, 255, 255, 0.1)">
      <b-card-title>Pinkstink</b-card-title>
      <b-card-body>Host Path</b-card-body>
    </b-card>
  </b-col> -->
</template>

<script>
// import ServerPreStartDownloadTasks from "./ServerTasks/ServerPreStartDownloadTasks.vue";

export default {
  // components: { ServerPreStartDownloadTasks },
  name: "HostFtpUsers",
  created() {
    const that = this;
    this.initializing = true
    this.getFtpUsers()
      .finally(() => that.initializing = false)
  },
  data: (that) => {
    return {
      hostAlias: that.$route.params.hostAlias,
      users: [],
      initializing: false,
      error: undefined,
      deleteInvokeMethod: undefined,
      isSaving: false,
      isDeleting: false,
      modalTitle: undefined,
      modalAction: undefined,
      allowUserNameEdit: false,
      formError: undefined,
      form: {
        userName: undefined,
        password: undefined,
        forbiddenPaths: undefined,
      }
    }
  },
  methods: {
    getFtpUsers() {
      const that = this;
      return that.$services.api.getFtpUsers(that.hostAlias)
        .then((data) => {
          data = data.map((value) => {
            return {
              ...value,
              forbiddenPaths: value.forbiddenPaths?.split(';')
            }
          });
          that.users = data;
        })
        .catch(err => {
          that.error = err
        })
    },
    confirmDelete(callback) {
      this.isDeleting = true;
      this.deleteInvokeMethod()
        .then(this.getFtpUsers)
        .then(callback)
        .finally(() => this.isDeleting = false);
    },
    openNewFtpUserModal() {
      this.modalTitle = "New FTP User";
      this.modalAction = this.createFtpUser;
      this.form = {};
      this.allowUserNameEdit = true;
      this.formError = undefined;
      this.$bvModal.show("ftp-user-modal");
    },
    openEditFtpUserModal(ftpUser) {
      this.modalTitle = "Edit FTP User: " + ftpUser.userName;
      this.modalAction = this.updateFtpUser;
      this.allowUserNameEdit = false;
      this.formError = undefined;
      this.form = { ...ftpUser, forbiddenPaths: ftpUser.forbiddenPaths?.join('\n') };
      this.$bvModal.show("ftp-user-modal");
    },
    createFtpUser(callback) {
      const that = this;
      this.isSaving = true;
      const data = { ...this.form, forbiddenPaths: this.form?.forbiddenPaths?.replace('\n', ';') };
      console.log(data)
      this.$services.api
        .createFtpUser(this.hostAlias, data)
        .then(this.getFtpUsers)
        .then(callback)
        .catch((err) => that.formError = err)
        .finally(() => that.isSaving = false);
    },
    updateFtpUser(callback) {
      const that = this
      this.isSaving = true;
      const data = { ...this.form, forbiddenPaths: this.form?.forbiddenPaths?.replace('\n', ';') };
      delete data.userName
      console.log(data)
      this.$services.api
        .updateFtpUser(this.hostAlias, this.form.userName, data)
        .then(this.getFtpUsers)
        .then(callback)
        .catch((err) => { })
        .finally(() => that.isSaving = false);
    },
    deleteFtpUser(userName) {
      this.isDeleting = false;
      this.deleteInvokeMethod = () => this.$services.api.deleteFtpUser(this.hostAlias, userName);
      this.$bvModal.show("delete-confirm");
    },
    localDateStringString(str) {
      let date = new Date(str);
      let duration = date - Date.now();
      return format(duration);
    }
  },
  computed: {
    userNameValid() {
      if (!this.form.userName) return false;
      if (this.form.userName.trim().length <= 0) return false;
      return undefined;
    },
    passwordValid() {
      if (!this.form.password) return false;
      if (this.form.password.trim().length <= 0) return false;
      return undefined;
    },
    canFormSave() {
      return this.userNameValid === undefined && this.passwordValid === undefined && !this.isSaving
    }
  }
}
</script>


<style lang="scss" scoped>
.centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  height: 100%;
}

.server-card {
  padding: 0;

  .server-card-navigation-wrapper {
    padding: 15px;
    border: 0.1rem solid rgba(0, 0, 0, 0);
    border-radius: 0.25rem;
    background: rgb(42, 42, 42);
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: inherit;

    &:hover {
      border: 0.1rem solid rgb(65, 65, 65);
    }

    .server-name {
      flex-grow: 1;
    }

    .server-alias {
      color: rgb(150, 150, 150);
    }
  }
}
</style>