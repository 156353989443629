<template>
  <div class="w-100 px-2 mb-2 d-flex flex-column">
    <row>
      <game-server-card v-for="gameServer in host.gameServers" :key="host.alias + '-' + gameServer.id"
        :gameServer="gameServer" :hostAlias="host.alias" />
    </row>

    <row class="mt-auto text-muted">
      <b-col xs="1" class="align-middle my-2 text-right">
        <span v-if="host.updating">Updating</span>
        <span v-else-if="host.updateError">{{ host.updateError }}</span>
        <span v-else-if="host.lastUpdated">
          Updated: {{ localUpdatedString(host.lastUpdated) }}
        </span>
      </b-col>
    </row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import GameServerCard from "../../GameServerCard.vue";

export default {
  name: "HostServers",
  components: { GameServerCard },
  created() {
    const that = this;
    that.fetchGameServers();
    that.fetchServersInterval = setInterval(() => that.fetchGameServers(), 5000);
  },
  data: (that) => {
    return {
      fetchServersInterval: undefined,
      hostAlias: that.$route.params.hostAlias
    };
  },
  methods: {
    localUpdatedString(epoch) {
      let date = new Date(epoch);
      return date.toLocaleString();
    },
    fetchGameServers() {
      for (let host in this.$store.state.hosts) {
        this.$services.api.getGameServers(this.$store.state.hosts[host], { timeout: 5000 });
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.fetchServersInterval);
  },
  computed: {
    ...mapState({
      host(state) {
        return state.hosts[this.$route.params.hostAlias];
      }
    }),
  }
}
</script>

<style></style>