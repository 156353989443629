<template>
  <b-col sm="12" class="server-card">
    <router-link :to="{ name: 'servers.info.overview', params: { serverId: gameServer.id, hostAlias: hostAlias } }" class="server-card-navigation-wrapper py-2 mb-2">
      <div class="server-name text-truncate">
        <div>
          {{ gameServer.displayName }}
        </div>
        <div>
          <div class="server-alias">{{ gameServer.hostAlias }}</div>
        </div>
      </div>
      <template v-if="!isGameServerStopped">
        <div v-if="gameServer.queuedPlayers > 0" class="server-info mr-2">{{ gameServer.connectedPlayers }} ({{ gameServer.queuedPlayers }}) players</div>
        <div v-else class="server-players mr-2">{{ gameServer.connectedPlayers }} players</div>
        <div class="server-fps mr-4">{{ gameServer.fps }} fps</div>
      </template>
      <div class="server-connect mr-4">{{ gameServer.serverIp }}:{{ gameServer.serverPort }}</div>
      <div class="server-status">{{ gameServer.state }}</div>
    </router-link>
  </b-col>
</template>

<script>
export default {
  name: "GameServerCard",
  props: ["gameServer", "hostAlias"],
  data: () => {
    return {
      serverId: undefined,
    };
  },
  computed: {
    isGameServerStopped() {
      if (!this.gameServer) return undefined;

      return this.gameServer.state === "Stopped" || this.gameServer.state === "Errored";
    },
  },
};
</script>

<style lang="scss" scoped>
.server-card {
  padding: 0;

  .server-card-navigation-wrapper {
    padding: 15px;
    border: 0.1rem solid rgba(0, 0, 0, 0);
    border-radius: 0.25rem;
    background: rgb(42, 42, 42);
    display: flex;
    align-items: center;
    cursor: pointer;
    color: inherit;
    text-decoration: inherit;

    &:hover {
      border: 0.1rem solid rgb(65, 65, 65);
    }

    .server-name {
      flex-grow: 1;
    }

    .server-alias {
      color: rgb(150, 150, 150);
    }
  }
}
</style>