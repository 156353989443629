<template>
  <div class="vh-100 d-flex flex-column">
    <b-navbar toggleable="sm" variant="faded" type="dark">
      <b-navbar-brand to="/" exact exact-active-class="active"> Rust Process Manager </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <!-- <b-nav-item to="/" exact exact-active-class="active"> Servers </b-nav-item> -->
          <!-- <b-nav-item v-if="$store.getters.admin" to="/hosts" active-class="active"> Hosts </b-nav-item> -->
          <b-nav-item v-on:click="logout">
            Logout
            <b-icon icon="arrow-clockwise" animation="spin" v-if="loggingOut" />
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-container fluid class="flex-grow-1 d-flex flex-column">
      <router-view />
    </b-container>
  </div>
</template>

<script>
export default {
  name: "GlobalLayout",
  data: () => {
    return {
      loggingOut: false,
    };
  },
  methods: {
    logout() {
      this.loggingOut = true;
      let that = this;
      this.$services.auth.logout().then(() => {
        that.$router.push({ name: "login" });
      });
    },
  },
};
</script>

<style></style>