<template>
  <b-container>
    <b-row v-if="!loggingIn" class="min-vh-100" align-h="center" align-v="center">
      <b-col sm="4">
        <b-card bg-variant="none" :img-src="rustLogo" img-top>
          <div class="text-center">
            <b-button v-on:click="$services.auth.login" variant="primary">Login with Steam</b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="min-vh-100" align-h="center" align-v="center" v-else>
      <b-col class="text-center">
        <h1>Logging In...</h1>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import rustLogo from "../assets/rust-logo-square.jpg";

export default {
  mounted() {
    if (this.$route.query.code) {
      const authCode = this.$route.query.code;
      this.$router.replace({
        query: { ...this.$route.query, code: undefined },
      });
      let that = this;
      this.$services.auth
        .handleLoginCallback(authCode)
        .then(() => {
          that.$router.push({ name: "home" });
        })
        .catch((e) => {
          console.error("failure logging in", e);
        });
    }
  },
  data: (that) => {
    return {
      loggingIn: !!that.$route.query.code,
      rustLogo: rustLogo,
    };
  },
};
</script>

<style></style>