<template>
  <b-col lg="12" class="mx-auto d-flex flex-column">
    <div v-if="!timedEvents" class="flex-grow m-auto text-center justify-self-center">
      <h1>Loading Schedules...</h1>
    </div>
    <div v-else>
      <div class="events-container d-flex">
        <div v-for="timedEvent in timedEvents" :key="'event-' + timedEvent.id" class="schedule-card p-3 m-1 d-flex flex-column mb-2">
          <div class="schedule-card-heading mb-3 text-truncate d-flex justify-content-between align-items-center">
            <div>
              <h4>{{ timedEvent.displayName }}</h4>
              <div class="d-flex">
                <h6 class="text-muted pr-1" v-for="(part, i) in timedEvent.expression.split(' ')" :key="'expression-' + i">{{ part }}</h6>
                <h6 class="text-muted pr-1">{{ timedEvent.timeZone }}</h6>
              </div>
            </div>
            <div>
              <h6 :class="{ 'text-right': true, 'text-success': timedEvent.enabled, 'text-danger': !timedEvent.enabled }">{{ timedEvent.enabled ? "Enabled" : "Disabled" }}</h6>
              <h6 class="text-muted text-right">{{ nextExecutionString(timedEvent.nextExecuction) }}</h6>
            </div>
          </div>
          <div class="event-actions-wrapper p-3 mb-3">
            <div v-if="!timedEvent.actions || timedEvent.actions.length < 1" class="align-items-center text-center">No Actions</div>
            <div class="d-flex align-items-center p-2 mb-2 event-action" v-for="action in timedEvent.actions" :key="'action-' + action.id">
              <div class="pr-2">{{ action.action }}</div>
              <span class="pr-2 text-muted">|</span>
              <div class="flex-grow-1" style="word-break: break-all">{{ action.arguments }}</div>
              <b-button-group>
                <b-button variant="outline-warning" size="sm" @click="() => openEditActionModal(timedEvent.id, action)">Edit</b-button>
                <b-button variant="outline-danger" size="sm" @click="() => deleteAction(timedEvent.id, action.id)">Delete</b-button>
              </b-button-group>
            </div>
            <b-button variant="outline-success" class="float-right mt-1" @click="() => openNewActionModal(timedEvent.id)" size="sm">Create Action</b-button>
          </div>
          <div>
            <div class="float-right">
              <b-button variant="outline-danger" size="sm" @click="() => deleteTimedEvent(timedEvent.id)" class="mr-2">Delete</b-button>
              <b-button variant="outline-warning" size="sm" @click="() => openEditEventModal(timedEvent)">Edit</b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <div class="float-right">
          <b-button variant="outline-success" size="sm" @click="() => openNewEventModal()">Create New Event</b-button>
        </div>
      </div>
    </div>

    <b-modal id="create-new-action" title="Create New Action" size="md" centered no-close-on-backdrop hide-header-close no-close-on-esc>
      <b-form-group label="Type:" label-for="actionType">
        <b-form-select id="actionType" v-model="newAction.action" :options="actionTypes"></b-form-select>
      </b-form-group>
      <b-form-group label="Arguments:" label-for="actionArguments">
        <b-form-textarea id="actionArguments" v-model="newAction.arguments" required />
      </b-form-group>
      <template #modal-footer="{ ok, cancel }">
        <b-button variant="danger" @click="cancel" :disabled="isSaving">Cancel</b-button>
        <b-button variant="success" @click="() => createAction(ok)" :disabled="isSaving">Create</b-button>
      </template>
    </b-modal>

    <b-modal id="edit-action" title="Edit Action" size="md" centered no-close-on-backdrop hide-header-close no-close-on-esc>
      <b-form-group label="Type:" label-for="actionType">
        <b-form-select id="actionType" v-model="editAction.action" :options="actionTypes"></b-form-select>
      </b-form-group>
      <b-form-group label="Arguments:" label-for="actionArguments">
        <b-form-textarea id="actionArguments" v-model="editAction.arguments" required />
      </b-form-group>
      <template #modal-footer="{ ok, cancel }">
        <b-button variant="danger" @click="cancel" :disabled="isSaving">Cancel</b-button>
        <b-button variant="success" @click="() => updateAction(ok)" :disabled="isSaving">Save</b-button>
      </template>
    </b-modal>

    <b-modal id="create-new-event" title="Create New Event" size="lg" centered no-close-on-backdrop hide-header-close no-close-on-esc>
      <b-form-group label="Display Name:" label-for="displayName">
        <b-form-input id="displayName" v-model="newEvent.displayName" required />
      </b-form-group>
      <b-input-group class="justify-content-between">
        <b-form-group label="Seconds:" label-for="scheduleSeconds">
          <b-form-input id="scheduleSeconds" v-model="newEvent.schedule.seconds" required />
        </b-form-group>
        <b-form-group label="Minutes:" label-for="scheduleMinutes">
          <b-form-input id="scheduleMinutes" v-model="newEvent.schedule.minutes" required />
        </b-form-group>
        <b-form-group label="Hours:" label-for="scheduleHours">
          <b-form-input id="scheduleHours" v-model="newEvent.schedule.hours" required />
        </b-form-group>
        <b-form-group label="Day of Month:" label-for="scheduleDom">
          <b-form-input id="scheduleDom" v-model="newEvent.schedule.dom" required />
        </b-form-group>
        <b-form-group label="Month:" label-for="scheduleMonth">
          <b-form-input id="scheduleMonth" v-model="newEvent.schedule.month" required />
        </b-form-group>
        <b-form-group label="Day of Week:" label-for="scheduleDow">
          <b-form-input id="scheduleDow" v-model="newEvent.schedule.dow" required />
        </b-form-group>
      </b-input-group>
      <b-form-group label="Time Zone:" label-for="timeZone">
        <b-form-select v-model="newEvent.timeZone" :options="tZones" required />
      </b-form-group>
      <b-form-checkbox id="enabled" v-model="newEvent.enabled">Enabled</b-form-checkbox>

      <template #modal-footer="{ ok, cancel }">
        <b-button variant="danger" @click="cancel" :disabled="isSaving">Cancel</b-button>
        <b-button variant="success" @click="() => createEvent(ok)" :disabled="isSaving">Create</b-button>
      </template>
    </b-modal>

    <b-modal id="edit-event" title="Edit Event" size="lg" centered no-close-on-backdrop hide-header-close no-close-on-esc>
      <b-form-group label="Display Name:" label-for="displayName">
        <b-form-input id="displayName" v-model="editEvent.displayName" required />
      </b-form-group>
      <b-input-group class="justify-content-between">
        <b-form-group label="Seconds:" label-for="scheduleSeconds">
          <b-form-input id="scheduleSeconds" v-model="editEvent.schedule.seconds" required />
        </b-form-group>
        <b-form-group label="Minutes:" label-for="scheduleMinutes">
          <b-form-input id="scheduleMinutes" v-model="editEvent.schedule.minutes" required />
        </b-form-group>
        <b-form-group label="Hours:" label-for="scheduleHours">
          <b-form-input id="scheduleHours" v-model="editEvent.schedule.hours" required />
        </b-form-group>
        <b-form-group label="Day of Month:" label-for="scheduleDom">
          <b-form-input id="scheduleDom" v-model="editEvent.schedule.dom" required />
        </b-form-group>
        <b-form-group label="Month:" label-for="scheduleMonth">
          <b-form-input id="scheduleMonth" v-model="editEvent.schedule.month" required />
        </b-form-group>
        <b-form-group label="Day of Week:" label-for="scheduleDow">
          <b-form-input id="scheduleDow" v-model="editEvent.schedule.dow" required />
        </b-form-group>
      </b-input-group>
      <b-form-group label="Time Zone:" label-for="timeZone">
        <b-form-select v-model="editEvent.timeZone" :options="tZones" required />
      </b-form-group>
      <b-form-checkbox id="enabled" v-model="editEvent.enabled">Enabled</b-form-checkbox>

      <template #modal-footer="{ ok, cancel }">
        <b-button variant="danger" @click="cancel" :disabled="isSaving">Cancel</b-button>
        <b-button variant="success" @click="() => updateEvent(ok)" :disabled="isSaving">Save</b-button>
      </template>
    </b-modal>

    <b-modal id="delete-confirm" size="sm" title="Confirm Deletion" centered>
      <span>Are you sure you want to continue?</span>

      <template #modal-footer="{ ok, cancel }">
        <b-button variant="primary" @click="cancel" :disabled="isDeleting">Cancel</b-button>
        <b-button variant="danger" @click="() => confirmDelete(ok)" :disabled="isDeleting">Delete</b-button>
      </template>
    </b-modal>
  </b-col>
</template>

<script>
import { mapState } from "vuex";
import format from "format-duration";
import timeZones from "../../../utility/time-zones";

export default {
  name: "ServerSchedules",
  data(that) {
    return {
      refreshTimeout: undefined,
      newActionEventId: undefined,
      serverId: +that.$route.params.serverId,
      hostAlias: that.$route.params.hostAlias,
      isSaving: false,
      isDeleting: false,
      deleteInvokeMethod: undefined,
      tZones: timeZones,
      newEvent: {
        displayName: undefined,
        enabled: true,
        timeZone: undefined,
        schedule: {
          seconds: undefined,
          minutes: undefined,
          hours: undefined,
          dom: undefined,
          month: undefined,
          dow: undefined,
        },
      },
      editEvent: {
        displayName: undefined,
        enabled: true,
        timeZone: undefined,
        schedule: {
          seconds: undefined,
          minutes: undefined,
          hours: undefined,
          dom: undefined,
          month: undefined,
          dow: undefined,
        },
      },
      newAction: {
        action: "RconCommand",
        arguments: undefined,
      },
      editAction: {
        id: undefined,
        action: undefined,
        arguments: undefined,
      },
      actionTypes: [
        { value: "RconCommand", text: "Send RCON command" },
        { value: "MoveFiles", text: "Move files on server shutdown" },
        { value: "BackupFiles", text: "Backup files" },
        { value: "ChangeMap", text: "Change Server Map Url" },
        { value: "ChangeSeed", text: "Change Server Seed" },
        { value: "ChangeWorldSize", text: "Change Server World Size" },
      ],
    };
  },
  created() {
    this.loadServerTimedEvents();
    this.refreshTimeout = setInterval(this.loadServerTimedEvents.bind(this), 5000);
  },
  beforeDestroy() {
    if (!!this.refreshTimeout) clearTimeout(this.refreshTimeout);
  },
  methods: {
    confirmDelete(callback) {
      this.isDeleting = true;
      this.deleteInvokeMethod()
        .then(() => callback())
        .finally(() => (this.isDeleting = false));
    },
    openNewEventModal() {
      this.$bvModal.show("create-new-event");
    },
    openEditEventModal(event) {
      const splitExpression = event.expression.split(" ");
      this.editEvent = {
        ...event,
        schedule: {
          seconds: splitExpression[0],
          minutes: splitExpression[1],
          hours: splitExpression[2],
          dom: splitExpression[3],
          month: splitExpression[4],
          dow: splitExpression[5],
        },
      };
      this.$bvModal.show("edit-event");
    },
    createEvent(callback) {
      this.isSaving = true;
      const expression = this.newEvent.schedule.seconds + " " + this.newEvent.schedule.minutes + " " + this.newEvent.schedule.hours + " " + this.newEvent.schedule.dom + " " + this.newEvent.schedule.month + " " + this.newEvent.schedule.dow;
      const payload = { displayName: this.newEvent.displayName, enabled: this.newEvent.enabled, timeZone: this.newEvent.timeZone, expression: expression };
      this.$services.api
        .createTimedEvent(this.hostAlias, this.serverId, payload)
        .then(() => callback())
        .catch((err) => {})
        .finally(() => (this.isSaving = false));
    },
    updateEvent(callback) {
      this.isSaving = true;
      const expression = this.editEvent.schedule.seconds + " " + this.editEvent.schedule.minutes + " " + this.editEvent.schedule.hours + " " + this.editEvent.schedule.dom + " " + this.editEvent.schedule.month + " " + this.editEvent.schedule.dow;
      const payload = { displayName: this.editEvent.displayName, enabled: this.editEvent.enabled, timeZone: this.editEvent.timeZone, expression: expression };
      this.$services.api
        .updateTimedEvent(this.hostAlias, this.serverId, this.editEvent.id, payload)
        .then(() => callback())
        .catch((err) => {})
        .finally(() => (this.isSaving = false));
    },
    deleteTimedEvent(id) {
      this.isDeleting = false;
      this.deleteInvokeMethod = () => this.$services.api.deleteTimedEvent(this.hostAlias, this.serverId, id);
      this.$bvModal.show("delete-confirm");
    },
    createAction(callback) {
      this.isSaving = true;
      this.$services.api
        .createTimedEventAction(this.hostAlias, this.serverId, this.newActionEventId, this.newAction)
        .then(() => callback())
        .catch((err) => {})
        .finally(() => (this.isSaving = false));
    },
    updateAction(callback) {
      this.isSaving = true;
      this.$services.api
        .updateTimedEventAction(this.hostAlias, this.serverId, this.editActionEventId, this.editAction.id, { action: this.editAction.action, arguments: this.editAction.arguments })
        .then(() => callback())
        .catch((err) => {})
        .finally(() => (this.isSaving = false));
    },
    deleteAction(timedEventId, actionId) {
      this.isDeleting = false;
      this.deleteInvokeMethod = () => this.$services.api.deleteTimedEventAction(this.hostAlias, this.serverId, timedEventId, actionId);
      this.$bvModal.show("delete-confirm");
    },
    openNewActionModal(id) {
      if (this.newActionEventId !== id) {
        this.newAction.action = "RconCommand";
        this.newAction.arguments = undefined;
      }
      this.newActionEventId = id;
      this.$bvModal.show("create-new-action");
    },
    openEditActionModal(id, action) {
      this.editActionEventId = id;
      this.editAction = { ...action };
      this.$bvModal.show("edit-action");
    },
    loadServerTimedEvents() {
      this.$services.api.getServerTimedEvents(this.hostAlias, this.serverId).catch((e) => {
        console.error(e);
      });
    },
    nextExecutionString(nextExecutionDateString) {
      let date = new Date(nextExecutionDateString);
      let duration = date - Date.now();
      let formatted = format(duration, { leading: false }).split(":").reverse();
      let final = "";
      for (let i = formatted.length - 1; i >= 0; i--) {
        let indicator;
        switch (i) {
          case 0:
            indicator = "s";
            break;
          case 1:
            indicator = "m";
            break;
          case 2:
            indicator = "h";
            break;
          case 3:
            indicator = "d";
            break;
          default:
            continue;
        }
        let duration = this.removeLeadingZero(formatted[i]);
        final = `${final} ${duration}${indicator}`;
      }
      return final;
    },
    removeLeadingZero(str) {
      while (str.startsWith("0")) {
        str = str.substr(1);
      }
      return str;
    },
  },
  computed: {
    ...mapState({
      gameServer(state) {
        if (!state.hosts) return undefined;
        const host = state.hosts[this.hostAlias];
        if (!host) return undefined;
        const gameServers = host.gameServers?.filter((x) => x.id === +this.serverId);
        if (!gameServers) return undefined;
        return gameServers[0];
      },
      timedEvents(state) {
        if (!state.timedEvents) return undefined;
        const host = state.timedEvents[this.hostAlias];
        if (!host) return undefined;
        const timedEvents = host[this.serverId];
        if (!timedEvents) return undefined;
        return timedEvents;
      },
    }),
  },
};
</script>

<style lang="scss" scoped>
.events-container {
  flex-flow: wrap;
  align-items: flex-start;
}

.schedule-card {
  border-radius: 0.25rem;
  background: rgb(42, 42, 42);
  @media screen and (max-width: 1199px) {
    flex-basis: 100%;
  }
  @media screen and (min-width: 1200px) {
    flex-basis: calc(50% - 0.5rem);
  }
  box-sizing: border-box;

  .schedule-card-heading > h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  .spaced {
    letter-spacing: 5px;
  }

  .event-action {
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 0.25rem;
  }

  .event-actions-wrapper {
    background: #303030;
    border-radius: 0.25rem;
  }
}
</style>