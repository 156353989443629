<template>
  <div class="mx-auto d-flex flex-column">
    <div v-if="!preStartDownloadTasks" class="flex-grow m-auto text-center justify-self-center">
      <h3>Loading...</h3>
    </div>
    <div v-else>
      <div class="tasks-container d-flex">
        <div v-for="task in preStartDownloadTasks" :key="'event-' + task.id" class="schedule-card p-3 m-1 d-flex flex-column mb-2">
          <div class="schedule-card-body mb-3 d-flex justify-content-between align-items-center">
            <div>
              <h4>Url</h4>
              <pre>{{ task.downloadUrl }}</pre>
              <h4>Output Path</h4>
              <pre style="overflow-wrap: break-word">{{ task.outputPath }}</pre>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="d-flex">
              <h6 :class="{ 'text-right': true, 'text-success': task.enabled, 'text-danger': !task.enabled }">{{ task.enabled ? "Enabled" : "Disabled" }}</h6>
              <h6 :class="{ 'ml-2': true, 'text-right': true, 'text-success': task.continueOnError, 'text-danger': !task.continueOnError }">{{ task.continueOnError ? "Continue on Error" : "Stop on Error" }}</h6>
            </div>
            <div class="float-right">
              <b-button variant="outline-danger" size="sm" @click="() => deleteTask(task.id)" class="mr-2">Delete</b-button>
              <b-button variant="outline-warning" size="sm" @click="() => openEditTaskModal(task)">Edit</b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <div class="float-right">
          <b-button variant="outline-success" size="sm" @click="() => openNewTaskModal()">Create</b-button>
        </div>
      </div>
    </div>

    <b-modal id="create-new-task" title="Create New Task" size="lg" centered no-close-on-backdrop hide-header-close no-close-on-esc>
      <b-form-group label="Download Url:" label-for="downloadUrl">
        <b-form-input id="downloadUrl" v-model="newTask.downloadUrl" required />
      </b-form-group>
      <b-form-group label="Output Path:" label-for="outputPath">
        <b-form-input id="outputPath" v-model="newTask.outputPath" required />
      </b-form-group>
      <b-form-checkbox id="enabled" v-model="newTask.enabled">Enabled</b-form-checkbox>
      <b-form-checkbox id="continueOnError" v-model="newTask.continueOnError">Continue On Error</b-form-checkbox>

      <template #modal-footer="{ ok, cancel }">
        <b-button variant="danger" @click="cancel" :disabled="isSaving">Cancel</b-button>
        <b-button variant="success" @click="() => createTask(ok)" :disabled="isSaving">Create</b-button>
      </template>
    </b-modal>

    <b-modal id="edit-task" title="Edit Task" size="lg" centered no-close-on-backdrop hide-header-close no-close-on-esc>
      <b-form-group label="Download Url:" label-for="downloadUrl">
        <b-form-input id="downloadUrl" v-model="editTask.downloadUrl" required />
      </b-form-group>
      <b-form-group label="Output Path:" label-for="outputPath">
        <b-form-input id="outputPath" v-model="editTask.outputPath" required />
      </b-form-group>
      <b-form-checkbox id="enabled" v-model="editTask.enabled">Enabled</b-form-checkbox>
      <b-form-checkbox id="continueOnError" v-model="editTask.continueOnError">Continue On Error</b-form-checkbox>

      <template #modal-footer="{ ok, cancel }">
        <b-button variant="danger" @click="cancel" :disabled="isSaving">Cancel</b-button>
        <b-button variant="success" @click="() => updateTask(ok)" :disabled="isSaving">Save</b-button>
      </template>
    </b-modal>

    <b-modal id="delete-confirm" size="sm" title="Confirm Deletion" centered>
      <span>Are you sure you want to continue?</span>

      <template #modal-footer="{ ok, cancel }">
        <b-button variant="primary" @click="cancel" :disabled="isDeleting">Cancel</b-button>
        <b-button variant="danger" @click="() => confirmDelete(ok)" :disabled="isDeleting">Delete</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import format from "format-duration";

export default {
  name: "ServerPreStartDownloadTasks",
  data(that) {
    return {
      refreshTimeout: undefined,
      serverId: +that.$route.params.serverId,
      hostAlias: that.$route.params.hostAlias,
      isSaving: false,
      isDeleting: false,
      deleteInvokeMethod: undefined,
      newTask: {
        enabled: false,
        continueOnError: true,
        downloadUrl: undefined,
        outputPath: undefined,
      },
      editTask: {
        enabled: false,
        continueOnError: true,
        downloadUrl: undefined,
        outputPath: undefined,
      },
    };
  },
  created() {
    this.loadServerTasks();
    this.refreshTimeout = setInterval(this.loadServerTasks.bind(this), 5000);
  },
  beforeDestroy() {
    if (!!this.refreshTimeout) clearTimeout(this.refreshTimeout);
  },
  methods: {
    confirmDelete(callback) {
      this.isDeleting = true;
      this.deleteInvokeMethod()
        .then(() => callback())
        .finally(() => (this.isDeleting = false));
    },
    openNewTaskModal() {
      this.$bvModal.show("create-new-task");
    },
    openEditTaskModal(task) {
      this.editTask = { ...task };
      this.$bvModal.show("edit-task");
    },
    createTask(callback) {
      this.isSaving = true;
      this.$services.api
        .createPreStartDownloadTask(this.hostAlias, this.serverId, this.newTask)
        .then(() => callback())
        .catch((err) => {})
        .finally(() => (this.isSaving = false));
    },
    updateTask(callback) {
      this.isSaving = true;
      this.$services.api
        .updatePreStartDownloadTask(this.hostAlias, this.serverId, this.editTask.id, this.editTask)
        .then(() => callback())
        .catch((err) => {})
        .finally(() => (this.isSaving = false));
    },
    deleteTask(id) {
      this.isDeleting = false;
      this.deleteInvokeMethod = () => this.$services.api.deletePreStartDownloadTask(this.hostAlias, this.serverId, id);
      this.$bvModal.show("delete-confirm");
    },
    loadServerTasks() {
      this.$services.api.getServerPreStartDownloadTasks(this.hostAlias, this.serverId).catch((e) => {
        console.error(e);
      });
    },
    localDateStringString(str) {
      let date = new Date(str);
      let duration = date - Date.now();
      return format(duration);
    },
  },
  computed: {
    ...mapState({
      gameServer(state) {
        if (!state.hosts) return undefined;
        const host = state.hosts[this.hostAlias];
        if (!host) return undefined;
        const gameServers = host.gameServers?.filter((x) => x.id === +this.serverId);
        if (!gameServers) return undefined;
        return gameServers[0];
      },
      preStartDownloadTasks(state) {
        if (!state.preStartDownloadTasks) return undefined;
        const host = state.preStartDownloadTasks[this.hostAlias];
        if (!host) return undefined;
        const preStartDownloadTasks = host[this.serverId];
        if (!preStartDownloadTasks) return undefined;
        return preStartDownloadTasks;
      },
    }),
  },
};
</script>

<style lang="scss" scoped>
.tasks-container {
  flex-flow: wrap;
  align-items: flex-start;
}

.schedule-card {
  border-radius: 0.25rem;
  background: rgb(42, 42, 42);
  @media screen and (max-width: 1199px) {
    flex-basis: 100%;
  }
  @media screen and (min-width: 1200px) {
    flex-basis: calc(50% - 0.5rem);
    max-width: calc(50% - 0.5rem);
  }
  box-sizing: border-box;

  .schedule-card-body > h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  pre {
    overflow-wrap: break-word;
    word-break: break-all;
    white-space: pre-line;
  }

  .spaced {
    letter-spacing: 5px;
  }

  .event-action {
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 0.25rem;
  }

  .event-actions-wrapper {
    background: #303030;
    border-radius: 0.25rem;
  }
}
</style>