import Vue from 'vue'
import Vuex from 'vuex'
import { AUTH_READY, API_READY, SET_AUTH, SET_HOST, SET_TIMEDEVENTS, SET_TIMEDEVENT, REMOVE_TIMEDEVENT, SET_TIMEDEVENTACTION, REMOVE_TIMEDEVENTACTION, SET_PRESTARTDOWNLOADTASKS, SET_PRESTARTDOWNLOADTASK, REMOVE_PRESTARTDOWNLOADTASK } from './mutations';
import { initialized, authenticated, admin } from './getters';

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    initialization: {
      authentication: false,
      api: false
    },
    authentication: {
      accessToken: undefined,
      expiration: undefined,
      refreshToken: undefined,
      isAdmin: false,
      user: {
        id: undefined,
        name: undefined
      }
    },
    hosts: undefined,
    timedEvents: undefined,
    preStartDownloadTasks: undefined
  },
  mutations: {
    [SET_AUTH]: (state, authentication) => {
      state.authentication = authentication;
    },
    [AUTH_READY]: (state) => {
      state.initialization.authentication = true;
    },
    [API_READY]: (state) => {
      state.initialization.api = true;
    },
    [SET_HOST]: (state, host) => {
      const newHostsObj = { ...state.hosts };
      newHostsObj[host.alias] = host;
      state.hosts = newHostsObj;
    },
    [SET_TIMEDEVENTS]: (state, { hostAlias, serverId, timedEvents }) => {
      const newTimedEventsObj = { ...state.timedEvents };
      if (!newTimedEventsObj[hostAlias]) newTimedEventsObj[hostAlias] = {};
      newTimedEventsObj[hostAlias][serverId] = timedEvents;
      state.timedEvents = newTimedEventsObj;
    },
    [SET_TIMEDEVENT]: (state, { hostAlias, serverId, timedEvent }) => {
      const newTimedEventsObj = { ...state.timedEvents };
      if (!newTimedEventsObj[hostAlias]) newTimedEventsObj[hostAlias] = {};
      if (!newTimedEventsObj[hostAlias][serverId]) newTimedEventsObj[hostAlias][serverId] = [];
      const existingTimedEvents = newTimedEventsObj[hostAlias][serverId];
      const spliceIdx = existingTimedEvents.findIndex(x => x.id === +timedEvent.id);
      if (spliceIdx >= 0) {
        existingTimedEvents.splice(spliceIdx, 1, timedEvent);
      } else {
        existingTimedEvents.push(timedEvent);
      }
      state.timedEvents = newTimedEventsObj;
    },
    [REMOVE_TIMEDEVENT]: (state, { hostAlias, serverId, timedEventId }) => {
      const newTimedEventsObj = { ...state.timedEvents };
      if (!newTimedEventsObj[hostAlias]) throw new "unknown-host";
      if (!newTimedEventsObj[hostAlias][serverId]) throw new "unknown-server";
      const existingTimedEvents = newTimedEventsObj[hostAlias][serverId];
      const spliceIdx = existingTimedEvents.findIndex(x => x.id == +timedEventId);
      if (spliceIdx >= 0) {
        existingTimedEvents.splice(spliceIdx, 1);
        state.timedEvents = newTimedEventsObj;
      }
    },
    [SET_TIMEDEVENTACTION]: (state, { hostAlias, serverId, timedEventId, timedEventAction }) => {
      const newTimedEventsObj = { ...state.timedEvents };
      if (!newTimedEventsObj[hostAlias]) throw new "unknown-host";
      if (!newTimedEventsObj[hostAlias][serverId]) throw new "unknown-server";
      const existingTimedEvents = newTimedEventsObj[hostAlias][serverId];
      const timedEvent = existingTimedEvents.find(x => x.id == +timedEventId);
      if (!timedEvent) throw new "unknown-timed-event";
      const spliceIdx = timedEvent.actions.findIndex(x => x.id === +timedEventAction.id);
      if (spliceIdx >= 0) {
        timedEvent.actions.splice(spliceIdx, 1, timedEventAction);
      } else {
        timedEvent.actions.push(timedEventAction);
      }
      state.timedEvents = newTimedEventsObj;
    },
    [REMOVE_TIMEDEVENTACTION]: (state, { hostAlias, serverId, timedEventId, timedEventActionId }) => {
      const newTimedEventsObj = { ...state.timedEvents };
      if (!newTimedEventsObj[hostAlias]) throw new "unknown-host";
      if (!newTimedEventsObj[hostAlias][serverId]) throw new "unknown-server";
      const existingTimedEvents = newTimedEventsObj[hostAlias][serverId];
      const timedEvent = existingTimedEvents.find(x => x.id == +timedEventId);
      if (!timedEvent) throw new "unknown-timed-event";
      const spliceIdx = timedEvent.actions.findIndex(x => x.id === +timedEventActionId);
      if (spliceIdx >= 0) {
        timedEvent.actions.splice(spliceIdx, 1);
        state.timedEvents = newTimedEventsObj;
      }
    },
    [SET_PRESTARTDOWNLOADTASKS]: (state, { hostAlias, serverId, preStartDownloadTasks }) => {
      const newpreStartDownloadTasksObj = { ...state.preStartDownloadTasks };
      if (!newpreStartDownloadTasksObj[hostAlias]) newpreStartDownloadTasksObj[hostAlias] = {};
      newpreStartDownloadTasksObj[hostAlias][serverId] = preStartDownloadTasks;
      state.preStartDownloadTasks = newpreStartDownloadTasksObj;
    },
    [SET_PRESTARTDOWNLOADTASK]: (state, { hostAlias, serverId, preStartDownloadTask }) => {
      const newpreStartDownloadTasksObj = { ...state.preStartDownloadTasks };
      if (!newpreStartDownloadTasksObj[hostAlias]) newpreStartDownloadTasksObj[hostAlias] = {};
      if (!newpreStartDownloadTasksObj[hostAlias][serverId]) newpreStartDownloadTasksObj[hostAlias][serverId] = [];
      const existingpreStartDownloadTasks = newpreStartDownloadTasksObj[hostAlias][serverId];
      const spliceIdx = existingpreStartDownloadTasks.findIndex(x => x.id === +preStartDownloadTask.id);
      if (spliceIdx >= 0) {
        existingpreStartDownloadTasks.splice(spliceIdx, 1, preStartDownloadTask);
      } else {
        existingpreStartDownloadTasks.push(preStartDownloadTask);
      }
      state.preStartDownloadTasks = newpreStartDownloadTasksObj;
    },
    [REMOVE_PRESTARTDOWNLOADTASK]: (state, { hostAlias, serverId, preStartDownloadTaskId }) => {
      const newpreStartDownloadTasksObj = { ...state.preStartDownloadTasks };
      if (!newpreStartDownloadTasksObj[hostAlias]) throw new "unknown-host";
      if (!newpreStartDownloadTasksObj[hostAlias][serverId]) throw new "unknown-server";
      const existingpreStartDownloadTasks = newpreStartDownloadTasksObj[hostAlias][serverId];
      const spliceIdx = existingpreStartDownloadTasks.findIndex(x => x.id == +preStartDownloadTaskId);
      if (spliceIdx >= 0) {
        existingpreStartDownloadTasks.splice(spliceIdx, 1);
        state.preStartDownloadTasks = newpreStartDownloadTasksObj;
      }
    }
  },
  getters: {
    initialized,
    authenticated,
    admin
  }
})
