import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

const baseUrl = process.env.VUE_APP_API_ENDPOINT + '/';

Vue.use(VueAxios, axios.create({
    baseURL: baseUrl
}))

// Extremely basic class with static functions that alias Vue.axios
export default {
    baseUrl: baseUrl,
    query(resource, params) { return Vue.axios.get(resource, { params }) },
    get(resource, config) { return Vue.axios.get(resource, config) },
    post(resource, params) { return Vue.axios.post(resource, params) },
    put(resource, params) { return Vue.axios.put(resource, params) },
    patch(resource, params) { return Vue.axios.patch(resource, params) },
    delete(resource) { return Vue.axios.delete(resource) },
}