<template>
  <div class="d-flex flex-grow-1 flex-wrap" style="max-width: 100%">
    <b-col lg="3" xl="2" class="mb-2">
      <server-quick-actions :gameServer="gameServer" :hostAlias="hostAlias" :ftpHost="host.ftpHost" :ftpPort="host.ftpPort" />
    </b-col>
    <b-col lg="9" xl="10" class="mb-2 flex-grow-1 d-flex flex-column" style="max-height: 100%">
      <server-console :gameServer="gameServer" :hostAlias="hostAlias" />
    </b-col>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ServerConsole from "../ServerConsole.vue";
import ServerQuickActions from "../ServerQuickActions.vue";

export default {
  name: "ServerOverview",
  components: { ServerConsole, ServerQuickActions },
  data: (that) => {
    return {
      hostAlias: that.$route.params.hostAlias,
      serverId: that.$route.params.serverId,
    };
  },
  computed: {
    ...mapState({
      host(state) {
        if (!state.hosts) return undefined;
        console.log(state.hosts[this.hostAlias])
        return state.hosts[this.hostAlias];
      },
      gameServer(state) {
        if (!state.hosts) return undefined;
        const host = state.hosts[this.hostAlias];
        if (!host) return undefined;
        const gameServers = host.gameServers?.filter((x) => x.id === +this.serverId);
        if (!gameServers) return undefined;
        return gameServers[0];
      },
    }),
    isGameServerStopped() {
      if (!this.gameServer) return undefined;

      return this.gameServer.state === "Stopped" || this.gameServer.state === "Errored";
    },
    isGameServerStarted() {
      return !this.isGameServerStopped;
    },
  },
};
</script>

<style></style>