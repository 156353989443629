<template>
  <b-col lg="8" class="mx-auto">
    <b-alert variant="success" :show="savedAlert" dismissible>Changes saved!</b-alert>
    <b-alert variant="danger" :show="!!error">An error occurred: {{ error }}</b-alert>

    <b-form @reset="onReset" @submit="onSave">
      <b-row>
        <b-col>
          <b-form-group label="Api Url:" label-for="apiHost" required>
            <b-form-input id="apiHost" v-model="form.apiHost" :state="apiHostValid"></b-form-input>
            <b-form-invalid-feedback :state="apiHostValid">Invalid URL</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Api Key:" label-for="apiKey">
            <b-form-input id="apiKey" v-model="form.apiKey" :state="apiKeyValid" required></b-form-input>
            <b-form-invalid-feedback :state="apiKey">An api key is required.</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="FTP Host:" label-for="ftpHost">
            <b-form-input id="ftpHost" v-model="form.ftpHost" :state="ftpHostValid" required></b-form-input>
            <b-form-invalid-feedback :state="ftpHost">A FTP Host is required.</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="FTP Port:" label-for="ftpPort">
            <b-form-input id="ftpPort" v-model="form.ftpPort" :state="ftpPortValid" required></b-form-input>
            <b-form-invalid-feedback :state="ftpPort">A FTP Port is required.</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-button-group class="float-right">
        <b-button type="reset" variant="danger" :disabled="saving">Reset</b-button>
        <b-button type="submit" variant="primary" :disabled="saving">Save</b-button>
      </b-button-group>
    </b-form>
  </b-col>
</template>

<script>
import { mapState } from "vuex";

const ipAddresRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
const urlRegex = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

export default {
  name: "HostSettings",
  created() {
    this.setFormDefaults();
  },
  beforeDestroy() {
    if (this.savedAlertTimeout) clearTimeout(this.savedAlertTimeout);
  },
  data(that) {
    return {
      hostAlias: that.$route.params.hostAlias,
      saving: false,
      savedAlert: false,
      error: undefined,
      form: {
        apiHost: undefined,
        apiKey: undefined,
        ftpHost: undefined,
        ftpPort: undefined
      },
    };
  },
  methods: {
    onReset(event) {
      event.preventDefault();
      this.setFormDefaults();
    },
    onSave(event) {
      event.preventDefault();
      if (this.saving) return;
      this.saving = true;
      this.$services.api
        .updateHost(this.hostAlias, this.form)
        .then(() => {
          this.error = undefined;
          this.savedAlert = true;
          if (this.savedAlertTimeout) clearTimeout(this.savedAlertTimeout);
          this.savedAlertTimeout = setTimeout(() => (this.savedAlert = false), 5000);
        })
        .catch((e) => {
          this.error = e;
        })
        .finally(() => {
          this.saving = false;
        });
    },
    setFormDefaults() {
      this.form.apiHost = this.host.apiHost;
      this.form.apiKey = this.host.apiKey;
      this.form.ftpHost = this.host.ftpHost;
      this.form.ftpPort = this.host.ftpPort;
    },
    ValidateIPaddress(ipaddress) {
      return ipAddresRegex.test(ipaddress);
    },
  },
  computed: {
    ...mapState({
      host(state) {
        console.log(state.hosts)
        if (!state.hosts) return undefined;
        return state.hosts[this.hostAlias];
      },
    }),
    apiHostValid() {
      if (!this.form.apiHost) return true;
      if (this.form.apiHost.trim().length <= 0) return true;
      return urlRegex.test(this.form.apiHost);
    },
    apiKeyValid() {
      if (!this.form.apiKey) return false;
      if (this.form.apiKey.trim().length <= 0) return false;
      return undefined;
    },

    ftpHostValid() {
      if (!this.form.ftpHost) return true;
      if (this.form.ftpHost.trim().length <= 0) return true;
      return undefined;
    }
  },
};
</script>

<style lang="scss" scoped></style>