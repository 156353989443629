<template>
  <div class="pb-2 mx-2">
    <div v-if="!gameServers">Loading</div>
    <div v-else-if="hostsLoadingError">{{ hostsLoadingError }}</div>
    <host-card v-else v-for="host of hosts" :key="host.alias" :host="host" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import GameServerCard from "../components/GameServerCard.vue";
import HostCard from "../components/HostCard.vue";
export default {
  components: { GameServerCard, HostCard },
  name: "HomeView",
  beforeMount() {
    const that = this;
    if (!this.$store.state.hosts) {
      this.$services.api
        .getHosts()
        .then(() => {
          that.fetchGameServers();
          that.fetchServersInterval = setInterval(() => that.fetchGameServers(), 5000);
        })
        .catch((err) => {
          console.error("failed to get hosts", err);
          that.hostsLoadingError = "Failed to retrieve hosts from api";
        });
    } else {
      that.fetchGameServers();
      that.fetchServersInterval = setInterval(() => that.fetchGameServers(), 5000);
    }
  },
  beforeDestroy() {
    clearInterval(this.fetchServersInterval);
  },
  computed: mapState({
    hosts: (state) => state.hosts,
    gameServers: (state) => {
      let gameServers = [];
      if (!state.hosts) return gameServers;
      for (let alias in state.hosts) {
        const host = state.hosts[alias];
        if (!host.gameServers) continue;
        for (let gameServer of host.gameServers) {
          gameServers.push({ hostAlias: alias, ...gameServer });
        }
      }
      gameServers = gameServers.sort((a, b) => a.displayName.localeCompare(b.displayName));
      return gameServers;
    },
  }),
  data: () => {
    return {
      fetchServersInterval: undefined,
      hostsLoadingError: undefined,
    };
  },
  methods: {
    fetchGameServers() {
      for (let host in this.$store.state.hosts) {
        this.$services.api.getGameServers(this.$store.state.hosts[host], { timeout: 5000 });
      }
    },
  }
};
</script>

<style lang="scss">
.no-border {
  .custom-control-label::before {
    box-shadow: none !important;
  }
}
</style>
