<template>
  <div class="position-relative flex-grow-1 d-flex flex-column" ref="server-console-wrapper">
    <div v-if="!wsConnected" class="console-loading-spinner">
      <b-spinner />
    </div>
    <div class="server-console-output-wrapper p-1 flex-grow-1" ref="server-console-output-wrapper">
      <div class="server-console-output" ref="server-console" />
    </div>
    <b-input-group class="server-console-input-group">
      <span class="server-console-input-leader">></span>
      <b-input autocomplete="off" :disabled="!wsConnected" class="server-console-input" v-model="messageToSend" v-on:keydown="onConsoleInputKeyDown" v-on:keyup.enter="sendMessage" />
    </b-input-group>
  </div>
</template>

<script>
import { debounce } from "debounce";
import { Terminal } from "xterm";
import { SearchAddon } from "xterm-addon-search";
import { SearchBarAddon } from "xterm-addon-search-bar";
import { FitAddon } from "xterm-addon-fit";
import "xterm/css/xterm.css";
const maxInputHistoryLength = 100;

const terminalProps = {
  disableStdin: true,
  cursorStyle: "underline",
  convertEol: true,
  allowTransparency: true,
  fontSize: 15,
  fontFamily: "monospace",
  theme: {
    cursor: "transparent",
    background: "#00000000",
  },
};

export default {
  name: "ServerConsole",
  props: ["gameServer", "hostAlias"],
  data: () => {
    return {
      wsHandle: undefined,
      wsConnected: false,
      wasAtBottom: true,
      consoleMessages: [],
      inputHistory: undefined,
      inputHistoryIdx: 0,
      messageToSend: undefined,
    };
  },
  created() {
    this.terminal = new Terminal(terminalProps);
    const searchAddon = new SearchAddon();
    const searchBar = new SearchBarAddon({ searchAddon });
    this.fitAddon = new FitAddon();
    this.terminal.attachCustomKeyEventHandler((e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "c") {
        document.execCommand("copy");
        return false;
      } else if ((e.ctrlKey || e.metaKey) && e.key === "f") {
        e.preventDefault();
        searchBar.show();
        return false;
      } else if (e.key === "Escape") {
        searchBar.hidden();
      }
      return true;
    });
    this.terminal.loadAddon(this.fitAddon);
    this.terminal.loadAddon(searchAddon);
    this.terminal.loadAddon(searchBar);

    this.loadInputHistory();
  },
  mounted() {
    const consoleEl = this.$refs["server-console"];
    const serverConsoleWrapperEl = this.$refs["server-console-wrapper"];
    const serverConsoleOutputWrapperEl = this.$refs["server-console-output-wrapper"];
    this.terminal.open(consoleEl);
    window.addEventListener(
      "resize",
      debounce(() => {
        if (!this.terminal.element) return;
        const wrapperTopPos = this.getTopOffset(serverConsoleWrapperEl);
        let maxHeight = window.innerHeight - wrapperTopPos;
        if (serverConsoleWrapperEl.scrollHeight > maxHeight) {
          let offset = serverConsoleWrapperEl.scrollHeight - serverConsoleOutputWrapperEl.scrollHeight;
          const height = Math.max(maxHeight - offset - 16, 500);
          serverConsoleOutputWrapperEl.style.height = height + "px";
        } else {
          serverConsoleOutputWrapperEl.style.height = "";
        }
        this.fitAddon.fit();
      }, 50)
    );
    this.fitAddon.fit();
    this.wsHandle = this.$services.api.getServerWebsocket(this.hostAlias, this.gameServer.id, this.onWsMessage.bind(this), this.onWsOpen.bind(this), this.onWsClose.bind(this));
    this.getOutputHistory();
  },
  computed: {
    localStorageKey() {
      return this.hostAlias + ":" + this.gameServer.id + ":console-history";
    },
  },
  methods: {
    onConsoleInputKeyDown(e) {
      const isKeyUp = e.keyCode === 38;
      const isKeyDown = e.keyCode === 40;
      const isCtrlDel = e.ctrlKey && e.keyCode === 46;
      if (!isKeyUp && !isKeyDown && !isCtrlDel) return;
      e.preventDefault();
      if (isCtrlDel) {
        if (this.removeFromInputHistory(this.inputHistoryIdx)) {
          this.inputHistoryIdx = Math.min(this.inputHistoryIdx, this.inputHistory.length);
          this.messageToSend = this.inputHistory[this.inputHistoryIdx];
        }
        return;
      }
      if (this.inputHistory.length < 1) return;
      let newIdx;
      if (isKeyUp) {
        newIdx = Math.min(this.inputHistoryIdx + 1, this.inputHistory.length);
      } else {
        newIdx = Math.max(this.inputHistoryIdx - 1, 0);
      }
      if (this.inputHistoryIdx === newIdx) return;
      this.inputHistoryIdx = newIdx;
      if (this.inputHistoryIdx > 0) {
        this.messageToSend = this.inputHistory[this.inputHistory.length - this.inputHistoryIdx];
      } else {
        this.messageToSend = undefined;
      }
    },
    getOutputHistory() {
      const that = this;
      this.$services.api
        .getServerOutput(this.hostAlias, this.gameServer.id)
        .then((output) => {
          let arr = output;
          for (let message of that.consoleMessages) {
            if (arr.some((x) => x.id === message.id)) continue;
            arr.push(message);
          }
          const outputToWrite = arr
            .sort((a, b) => Date.parse(a.time) > Date.parse(b.time))
            .map((x) => x.message)
            .join("\r\n");
          this.terminal.clear();
          this.terminal.write(outputToWrite);
        })
        .catch((err) => console.error("initial console output fetch", err))
        .finally(() => (that.consoleMessages = undefined));
    },
    loadInputHistory() {
      const localStorage = window.localStorage.getItem(this.localStorageKey);
      if (!localStorage) {
        this.inputHistory = [];
        return;
      }
      try {
        const parsed = JSON.parse(localStorage);
        if (!Array.isArray(parsed)) {
          window.localStorage.removeItem(this.localStorageKey);
          this.inputHistory = [];
          return;
        }
        this.inputHistory = parsed;
      } catch {
        this.inputHistory = [];
      }
    },
    addToInputHistory(message) {
      this.inputHistoryIdx = 0;
      if (this.inputHistory.length > 0 && this.inputHistory[this.inputHistory.length - 1] === message) return;
      this.inputHistory.push(message);
      const amountToDelete = this.inputHistory.length - maxInputHistoryLength;
      if (amountToDelete > 0) this.inputHistory.splice(0, amountToDelete);
      window.localStorage.setItem(this.localStorageKey, JSON.stringify(this.inputHistory));
    },
    removeFromInputHistory(idx) {
      if (idx < 0 || idx > this.inputHistory.length - 1) return false;
      this.inputHistory.splice(idx, 1);
      window.localStorage.setItem(this.localStorageKey, JSON.stringify(this.inputHistory));
      return true;
    },
    sendMessage() {
      if (!this.messageToSend || !(this.messageToSend.length > 0)) return;
      if (this.wsHandle.send) this.wsHandle.send(this.messageToSend);
      this.addToInputHistory(this.messageToSend);
      this.messageToSend = undefined;
    },
    onWsMessage(message) {
      let parsedMessage = JSON.parse(message);
      if (this.consoleMessages) this.consoleMessages.push(parsedMessage);
      this.terminal.write("\r\n" + parsedMessage.message);
    },
    onWsOpen() {
      this.wsConnected = true;
      this.$forceUpdate();
    },
    onWsClose() {
      this.wsConnected = false;
      this.$forceUpdate();
    },
    getTopOffset(elem) {
      var box = elem.getBoundingClientRect();

      var body = document.body;
      var docEl = document.documentElement;

      var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;

      var clientTop = docEl.clientTop || body.clientTop || 0;

      var top = box.top + scrollTop - clientTop;

      return Math.round(top);
    },
  },
  beforeDestroy() {
    if (this.wsHandle) this.wsHandle.close();
  },
};
</script>

<style lang="scss" scoped>
.console-loading-spinner {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 100;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0.25rem;
}

.server-console-output-wrapper {
  border-radius: 0.25rem;
  background: rgb(42, 42, 42);
}

.server-console-output {
  overflow: hidden;
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background: orange;
  }
}

.server-console-input-group {
  background-color: rgb(63, 63, 63);
  border-radius: 0.25rem;
  border-top-left-radius: initial;
  border-top-right-radius: initial;
  font-family: monospace;

  .server-console-input-leader {
    vertical-align: middle;
    padding: 0 5px;
    align-self: center;
    margin-bottom: 2px;
  }

  .server-console-input {
    background-color: transparent;
    border: none;
    color: inherit;
    padding-left: 0;
    box-sizing: border-box;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: transparent;

    &:focus {
      box-shadow: none;
      border-bottom-color: #fff;
    }
  }
}
</style>