import Vue from 'vue';
import httpService from './http.service';
import store from '../store';
import { API_READY, SET_HOST, SET_TIMEDEVENTS, SET_TIMEDEVENT, REMOVE_TIMEDEVENT, SET_PRESTARTDOWNLOADTASKS, SET_PRESTARTDOWNLOADTASK, REMOVE_PRESTARTDOWNLOADTASK, SET_TIMEDEVENTACTION, REMOVE_TIMEDEVENTACTION } from '../store/mutations';

async function initialize() {
    Vue.axios.interceptors.request.use(
        req => {
            req.headers['Authorization'] = 'Bearer ' + store.state?.authentication?.accessToken;
            return req;
        },
    );

    if (store.getters.authenticated) {
        await getHosts();
    }

    store.commit(API_READY);
}

if (!store.state.initialization.authentication) {
    let watcherUnsubscribe;
    watcherUnsubscribe = store.watch(state => state.initialization.authentication, initialized => {
        if (!initialized) return;
        watcherUnsubscribe();
        initialize();
    });
}
else {
    initialize();
}

export async function getHosts() {
    const res = await httpService.get('hosts');
    const hosts = res.data.data;
    for (let host of hosts) {
        store.commit(SET_HOST, host);
    }
}

export async function getFtpUsers(hostAlias, config) {
    try {
        const res = await httpService.get(`ftpusers/${hostAlias}`, config);
        const ftpUsers = res.data.data;
        return ftpUsers
    }
    catch (err) {
        console.error("failure getting ftpUsers", err);
    }
}

export async function createFtpUser(hostAlias, config){
    try {
        const res = await httpService.post(`ftpusers/${hostAlias}`, config);
        const ftpUser = res.data.data;
        return ftpUser
    }
    catch (err) {
        console.error("failure creating ftpUser", err);
    }
}

export async function updateFtpUser(hostAlias, username, config){
    try {
        const res = await httpService.patch(`ftpusers/${hostAlias}/${username}`, config);
        const ftpUser = res.data.data;
        return ftpUser
    }
    catch (err) {
        console.error("failure creating ftpUser", err);
    }
}

export async function deleteFtpUser(hostAlias, username){
    try {
        const res = await httpService.delete(`ftpusers/${hostAlias}/${username}`);
        const ftpUser = res.data.data;
        return ftpUser
    }
    catch (err) {
        console.error("failure creating ftpUser", err);
    }
}

export async function getGameServers(host, config) {
    host = { ...host, updating: true };
    store.commit(SET_HOST, host);
    try {
        const res = await httpService.get(`hosts/${host.alias}/servers`, config);
        const gameServers = res.data.data;
        host = {
            ...host,
            gameServers: gameServers,
            lastUpdated: Date.now(),
            updateError: undefined,
        };
        store.commit(SET_HOST, host);
    }
    catch (err) {
        console.error("failure updating host gameservers", err);
        host = {
            ...host,
            updateError: "Failure updating data",
        };
        store.commit(SET_HOST, host);
    }
    finally {
        host = {
            ...host,
            updating: false,
        };
        store.commit(SET_HOST, host);
    }
}

export async function getGameServer(hostAlias, serverId, config) {
    if (!store.state.hosts) {
        await getHosts();
    }

    let host = store.state.hosts[hostAlias];
    if (!host)
        throw "host-not-found";

    try {
        const res = await httpService.get(`hosts/${hostAlias}/servers/${serverId}`, config);
        const gameServer = res.data.data;
        const gameServers = [...host.gameServers ?? []];
        const index = gameServers.findIndex(x => x.id === +serverId);
        if (index === -1) {
            gameServers.push(gameServer);
        } else {
            gameServers.splice(index, 1, gameServer);
        }
        host = {
            ...host,
            gameServers: gameServers,
        };
        store.commit(SET_HOST, host);
    }
    catch (err) {
        console.log(err);
        throw "generic-failure";
    }
}

export async function updateServer(hostAlias, serverId, packet) {
    if (!store.state.hosts) {
        await getHosts();
    }

    let host = store.state.hosts[hostAlias];
    if (!host)
        throw "host-not-found";

    try {
        const res = await httpService.patch(`hosts/${hostAlias}/servers/${serverId}`, packet);
        const gameServer = res.data.data;
        const gameServers = [...host.gameServers ?? []];
        const index = gameServers.findIndex(x => x.id === +serverId);
        if (index === -1) {
            gameServers.push(gameServer);
        } else {
            gameServers.splice(index, 1, gameServer);
        }
        host = {
            ...host,
            gameServers: gameServers,
        };
        store.commit(SET_HOST, host);
    }
    catch (err) {
        console.log(err);
        throw "generic-failure";
    }
}

export async function getServerOutput(hostAlias, serverId) {
    try {
        const res = await httpService.get(`hosts/${hostAlias}/servers/${serverId}/output`);
        return res.data.data;
    }
    catch (err) {
        console.log(err);
        throw "generic-failure";
    }
}

export async function getServerTimedEvents(hostAlias, serverId) {
    try {
        const res = await httpService.get(`timedEvents/${hostAlias}/${serverId}`);
        const timedEvents = res.data.data;
        if (timedEvents == null) throw "timed-events-null"
        store.commit(SET_TIMEDEVENTS, { hostAlias, serverId, timedEvents });
    }
    catch (err) {
        console.log(err);
        throw "generic-failure";
    }
}

export async function updateTimedEvent(hostAlias, serverId, timedEventId, payload) {
    try {
        const res = await httpService.patch(`timedEvents/${hostAlias}/${serverId}/${timedEventId}`, payload);
        const timedEvent = res.data.data;
        if (timedEvent == null) throw "timed-event-null"
        store.commit(SET_TIMEDEVENT, { hostAlias, serverId, timedEvent });
    }
    catch (err) {
        console.log(err);
        throw "generic-failure";
    }
}

export async function createTimedEvent(hostAlias, serverId, payload) {
    try {
        const res = await httpService.post(`timedEvents/${hostAlias}/${serverId}`, payload);
        const timedEvent = res.data.data;
        if (timedEvent == null) throw "timed-event-null"
        store.commit(SET_TIMEDEVENT, { hostAlias, serverId, timedEvent });
    }
    catch (err) {
        console.log(err);
        throw "generic-failure";
    }
}

export async function deleteTimedEvent(hostAlias, serverId, timedEventId, payload) {
    try {
        const res = await httpService.delete(`timedEvents/${hostAlias}/${serverId}/${timedEventId}`);
        if (res.status !== 200) throw "unknown-error-deleting-timed-event";
        store.commit(REMOVE_TIMEDEVENT, { hostAlias, serverId, timedEventId });
    }
    catch (err) {
        console.log(err);
        throw "generic-failure";
    }
}

export async function createTimedEventAction(hostAlias, serverId, timedEventId, payload) {
    try {
        const res = await httpService.post(`timedEventActions/${hostAlias}/${timedEventId}`, payload);
        const timedEventAction = res.data.data;
        if (timedEventAction == null) throw "timed-event-action-null"
        store.commit(SET_TIMEDEVENTACTION, { hostAlias, serverId, timedEventId, timedEventAction });
    }
    catch (err) {
        console.log(err);
        throw "generic-failure";
    }
}

export async function updateTimedEventAction(hostAlias, serverId, timedEventId, timedEventActionId, payload) {
    try {
        const res = await httpService.patch(`timedEventActions/${hostAlias}/${timedEventId}/${timedEventActionId}`, payload);
        const timedEventAction = res.data.data;
        if (timedEventAction == null) throw "timed-event-action-null"
        store.commit(SET_TIMEDEVENTACTION, { hostAlias, serverId, timedEventId, timedEventAction });
    }
    catch (err) {
        console.log(err);
        throw "generic-failure";
    }
}

export async function deleteTimedEventAction(hostAlias, serverId, timedEventId, timedEventActionId) {
    try {
        const res = await httpService.delete(`timedEventActions/${hostAlias}/${timedEventId}/${timedEventActionId}`);
        if (res.status !== 200) throw "unknown-error-deleting-timed-action";
        store.commit(REMOVE_TIMEDEVENTACTION, { hostAlias, serverId, timedEventId, timedEventActionId });
    }
    catch (err) {
        console.log(err);
        throw "generic-failure";
    }
}

export function getServerWebsocket(hostAlias, serverId, onMessage, onOpen, onClose) {
    let ws;
    let shouldReconnect = true;

    function connect() {
        let url = httpService.baseUrl.replace(/^https:\/\//, "wss://").replace(/^http:\/\//, "ws://");
        url += `hosts/${hostAlias}/servers/${serverId}/ws`;
        ws = new WebSocket(url);

        ws.onopen = () => {
            ws.send(store.state.authentication.accessToken);
        };

        ws.onmessage = data => {
            let message = data.data;
            if (message === "AUTH_COMPLETE") return;
            if (message === "SERVER_CONNECTED") {
                if (typeof (onOpen) === "function")
                    onOpen();
            } else if (message === "SERVER_DISCONNECTED") {
                if (typeof (onOpen) === "function")
                    onClose();
            } else if (typeof (onMessage) === "function") {
                try {
                    onMessage(message);
                }
                catch { }
            }
        };

        ws.onclose = function (e) {
            if (typeof (onClose) === "function") {
                onClose();
            }
            if (shouldReconnect) {
                setTimeout(function () {
                    connect();
                }, 500);
            }
        };
    }

    connect();
    return {
        close: () => {
            shouldReconnect = false;
            ws?.close();
        },
        send: (message) => ws?.send(message)
    }
}

export async function startServer(hostAlias, serverId) {
    if (!store.state.hosts) {
        await getHosts();
    }

    let host = store.state.hosts[hostAlias];
    if (!host)
        throw "host-not-found";

    try {
        const res = await httpService.post(`hosts/${hostAlias}/servers/${serverId}/start`);
        const gameServer = res.data.data;
        const gameServers = [...host.gameServers ?? []];
        const index = gameServers.findIndex(x => x.id === +serverId);
        if (index === -1) {
            gameServers.push(gameServer);
        } else {
            gameServers.splice(index, 1, gameServer);
        }
        host = {
            ...host,
            gameServers: gameServers,
        };
        store.commit(SET_HOST, host);
    }
    catch (err) {
        console.log(err);
        throw "generic-failure";
    }
}

export async function stopServer(hostAlias, serverId) {
    if (!store.state.hosts) {
        await getHosts();
    }

    let host = store.state.hosts[hostAlias];
    if (!host)
        throw "host-not-found";

    try {
        const res = await httpService.post(`hosts/${hostAlias}/servers/${serverId}/stop`);
        const gameServer = res.data.data;
        const gameServers = [...host.gameServers ?? []];
        const index = gameServers.findIndex(x => x.id === +serverId);
        if (index === -1) {
            gameServers.push(gameServer);
        } else {
            gameServers.splice(index, 1, gameServer);
        }
        host = {
            ...host,
            gameServers: gameServers,
        };
        store.commit(SET_HOST, host);
    }
    catch (err) {
        console.log(err);
        throw "generic-failure";
    }
}

export async function killServer(hostAlias, serverId) {
    if (!store.state.hosts) {
        await getHosts();
    }

    let host = store.state.hosts[hostAlias];
    if (!host)
        throw "host-not-found";

    try {
        const res = await httpService.post(`hosts/${hostAlias}/servers/${serverId}/kill`);
        const gameServer = res.data.data;
        const gameServers = [...host.gameServers ?? []];
        const index = gameServers.findIndex(x => x.id === +serverId);
        if (index === -1) {
            gameServers.push(gameServer);
        } else {
            gameServers.splice(index, 1, gameServer);
        }
        host = {
            ...host,
            gameServers: gameServers,
        };
        store.commit(SET_HOST, host);
    }
    catch (err) {
        console.log(err);
        throw "generic-failure";
    }
}


export async function getServerPreStartDownloadTasks(hostAlias, serverId) {
    try {
        const res = await httpService.get(`prestartdownloadtasks/${hostAlias}/${serverId}`);
        const preStartDownloadTasks = res.data.data;
        if (preStartDownloadTasks == null) throw "pre-start-download-tasks-null"
        store.commit(SET_PRESTARTDOWNLOADTASKS, { hostAlias, serverId, preStartDownloadTasks });
    }
    catch (err) {
        console.log(err);
        throw "generic-failure";
    }
}

export async function updatePreStartDownloadTask(hostAlias, serverId, preStartDownloadTaskId, payload) {
    try {
        const res = await httpService.patch(`prestartdownloadtasks/${hostAlias}/${serverId}/${preStartDownloadTaskId}`, payload);
        const preStartDownloadTask = res.data.data;
        if (preStartDownloadTask == null) throw "pre-start-download-task-null"
        store.commit(SET_PRESTARTDOWNLOADTASK, { hostAlias, serverId, preStartDownloadTask });
    }
    catch (err) {
        console.log(err);
        throw "generic-failure";
    }
}

export async function createPreStartDownloadTask(hostAlias, serverId, payload) {
    try {
        const res = await httpService.post(`prestartdownloadtasks/${hostAlias}/${serverId}`, payload);
        const preStartDownloadTask = res.data.data;
        if (preStartDownloadTask == null) throw "pre-start-download-task-null"
        store.commit(SET_PRESTARTDOWNLOADTASK, { hostAlias, serverId, preStartDownloadTask });
    }
    catch (err) {
        console.log(err);
        throw "generic-failure";
    }
}

export async function deletePreStartDownloadTask(hostAlias, serverId, preStartDownloadTaskId) {
    try {
        const res = await httpService.delete(`prestartdownloadtasks/${hostAlias}/${serverId}/${preStartDownloadTaskId}`);
        if (res.status !== 200) throw "unknown-error-deleting-pre-start-download-task";
        store.commit(REMOVE_PRESTARTDOWNLOADTASK, { hostAlias, serverId, preStartDownloadTaskId });
    }
    catch (err) {
        console.log(err);
        throw "generic-failure";
    }
}