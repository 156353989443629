<template>
  <div class="flex-grow-1 d-flex flex-column">
    <b-alert v-if="error" variant="danger">{{ error }}</b-alert>
    <div v-if="gameServer" class="flex-grow-1 d-flex flex-column">
      <div class="mb-2 d-flex flex-wrap justify-content-between">
        <div class="flex-grow-1 heading-title">
          <h4>
            <router-link tag="span" style="cursor: pointer" :to="baseRoute" title="Overview" exact>{{ gameServer.displayName }}</router-link>
            <br />
            <router-link tag="small" style="cursor: pointer" :to="hostRoute" class="text-muted">{{ hostAlias }}</router-link>
          </h4>
        </div>
        <div class="d-flex align-self-end">
          <router-link v-for="route in routes" :key="route.title" :to="baseRoute + route.route" class="route-item" title="Overview" active-class="active" exact>
            <div class="route-item-content">
              <span class="route-item-icon"> </span>
              <span class="route-item-name">{{ route.title }}</span>
            </div>
          </router-link>
        </div>
      </div>
      <b-row class="mb-2 flex-grow-1 d-flex flex-row">
        <router-view />
      </b-row>
    </div>
    <div v-else class="flex-grow m-auto text-center">
      <h1>Loading Game Server...</h1>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ServerInfoView",
  created() {
    const that = this;
    if (!this.gameServer) {
      this.loadGameServer()
        .then(() => (that.getGameServerInterval = setTimeout(() => that.loadGameServer(), 500)))
        .catch((err) => (that.error = err));
    } else {
      this.getGameServerInterval = setTimeout(() => that.loadGameServer(), 500);
    }
  },
  data: (that) => {
    return {
      hostAlias: that.$route.params.hostAlias,
      serverId: that.$route.params.serverId,
      getGameServerInterval: undefined,
      error: undefined,
      routes: [
        { title: "Overview", icon: "", route: "" },
        { title: "Tasks", icon: "", route: "/tasks" },
        { title: "Schedules", icon: "", route: "/schedules" },
        { title: "Settings", icon: "", route: "/settings" },
      ],
    };
  },
  beforeDestroy() {
    clearTimeout(this.getGameServerInterval);
  },
  computed: {
    ...mapState({
      gameServer(state) {
        if (!state.hosts) return undefined;
        const host = state.hosts[this.hostAlias];
        if (!host) return undefined;
        const gameServers = host.gameServers?.filter((x) => x.id === +this.serverId);
        if (!gameServers) return undefined;
        return gameServers[0];
      },
    }),
    isGameServerStopped() {
      if (!this.gameServer) return undefined;
      return this.gameServer.state === "Stopped" || this.gameServer.state === "Errored";
    },
    isGameServerStarted() {
      return !this.isGameServerStopped;
    },
    baseRoute: (that) => `/${that.$route.params.hostAlias}/servers/${that.$route.params.serverId}`,
    hostRoute: (that) => `/${that.$route.params.hostAlias}`,
  },
  methods: {
    loadGameServer() {
      const that = this;
      this.$services.api.getGameServer(this.hostAlias, this.serverId, { timeout: 1000 }).finally(() => {
        that.getGameServerInterval = setTimeout(() => that.loadGameServer(), 1000);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.heading-title {
  border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
}

.route-item {
  display: block;
  border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
  padding: 1px 1px 0px 1px;
  text-decoration: none;
  color: inherit;

  .route-item-content {
    padding: 1rem;
  }

  &.active {
    border: 1px solid rgba($color: #fff, $alpha: 0.1);
    border-bottom: none;
    border-top-left-radius: 0.25em;
    border-top-right-radius: 0.25em;
    padding: 0px 0px 1px 0px;
  }
}
</style>
