import store from '../../store';

export default async (to, from, next) => {
    const isAuthenticated = store.getters.authenticated;
    const requiresAuth = to.meta.auth;
    if (typeof (requiresAuth) == 'boolean') {
        if (requiresAuth && !isAuthenticated)
            return next({ name: 'login' });

        if (!requiresAuth && isAuthenticated)
            return next({ name: 'servers.list' });
    }

    return next();
}