<template>
  <b-container>
    <b-row class="min-vh-100" align-h="center" align-v="center">
      <b-col class="text-center">
        <h1>Initializing...</h1>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "InitializingView"
};
</script>

<style>
</style>