<template>
  <b-btn-group class="w-100">
    <b-button @click="() => onClick(paramName, true)" style="min-width: 50%" :disabled="paramState || isLoading" :variant="paramState ? 'success' : 'outline-success'">
      <template v-if="!isLoading || paramState">Yes</template>
      <template v-else><b-spinner small></b-spinner></template>
    </b-button>
    <b-button @click="() => onClick(paramName, false)" style="min-width: 50%" :disabled="!paramState || isLoading" :variant="!paramState ? 'danger' : 'outline-danger'">
      <template v-if="!isLoading || !paramState">No</template>
      <template v-else><b-spinner small></b-spinner></template>
    </b-button>
  </b-btn-group>
</template>

<script>
export default {
  name: "YesNoButtonGroup",
  props: ["paramName", "paramState", "onClick", "isLoading"],
};
</script>

<style lang="scss" scoped>
.btn.disabled {
  opacity: 1 !important;
}
.btn.disabled,
.btn:hover {
  z-index: inherit;
}
.btn:hover :not(.btn.disabled) {
  background: inherit;
}
</style>