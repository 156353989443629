import jwt_decode from 'jwt-decode';
import store from '../store';
import router from '../router';
import httpService from './http.service';
import { AUTH_READY, SET_AUTH } from '../store/mutations';

const accessTokenRefreshOffset = 60000;
const accessTokenCheckInterval = accessTokenRefreshOffset * 0.25;

const accessTokenLocalStorageKey = 'access_token';
const refreshTokenLocalStorageKey = 'refresh_token';

let lastTokenCheck = 0;

window.addEventListener("focus", (event) => {
    if (!store?.getters.authenticated) return;
    checkAccessTokenValidity();
});

async function initialize() {
    setInterval(checkAccessTokenValidity, accessTokenCheckInterval);
    if (loadTokensFromLocalStorage()) {
        await checkAccessTokenValidity();
    }
    lastTokenCheck = Date.now()
    store.commit(AUTH_READY);
}

function loadTokensFromLocalStorage() {
    let accessToken = window.localStorage.getItem(accessTokenLocalStorageKey);
    let refreshToken = window.localStorage.getItem(refreshTokenLocalStorageKey);

    // No refresh token found? bail out, we're done here..
    if (refreshToken === null) {
        storeAuthentication(undefined, undefined, undefined, false, undefined, undefined);
        console.log('no refresh token in local storage')
        return false;
    }

    // Was an access token present?
    // If so lets try and decode it to obtain it's expiration
    if (accessToken !== null) {
        try {
            let decodedAccessToken = jwt_decode(accessToken);
            const tokenExpiration = decodedAccessToken.exp * 1000;
            // We managed to decode the access token, if it's still valid lets set it as the authentication and continue on.
            if (tokenExpiration > Date.now()) {
                storeAuthentication(accessToken, tokenExpiration, refreshToken, decodedAccessToken.is_admin == '1', decodedAccessToken.nameid, decodedAccessToken.name);
                return true;
            }
        }
        catch {
            // We failed at some point in retrieving a new token, do nothing
            console.log('failed to load access token from local storage', e)
        }
    }

    storeAuthentication(undefined, undefined, refreshToken, false, undefined, undefined);
    return true
}

async function checkAccessTokenValidity() {
    if (Date.now() - lastTokenCheck < accessTokenCheckInterval) return;
    lastTokenCheck = Date.now();

    const refreshToken = store.state?.authentication?.refreshToken;
    let accessTokenNeedsRefresh = true;
    const accessToken = store.state?.authentication?.accessToken;
    // Check if an access token exists
    if (typeof (accessToken) == 'string') {
        // an access token exists, lets try decode it and check if it's expired
        try {
            let decodedAccessToken = jwt_decode(accessToken);
            const tokenExpiration = decodedAccessToken.exp * 1000;
            accessTokenNeedsRefresh = tokenExpiration - Date.now() <= accessTokenRefreshOffset
        } catch (e) {
            console.warn("failed to validate access token", e)
        }
    }

    if (accessTokenNeedsRefresh) {
        console.warn('access token needs refreshing');
        if (typeof (refreshToken) != 'string') {
            console.warn('access token needs refreshing, although refresh token is missing');
            storeAuthentication(undefined, undefined, undefined, false, undefined, undefined);
            if (router.currentRoute.name === 'login' || !store.getters.initialized) return;
            router.push({ name: 'login' });
            return;
        }
        try {
            await refreshAccessToken(refreshToken);
        } catch (e) {
            console.warn('failed to refresh access token', e);
        }
    }
}

function storeAuthentication(accessToken, tokenExpiration, refreshToken, isAdmin, id, name) {
    if (accessToken === undefined) {
        window.localStorage.removeItem(accessTokenLocalStorageKey);
    } else {
        window.localStorage.setItem(accessTokenLocalStorageKey, accessToken);
    }

    if (refreshToken === undefined) {
        window.localStorage.removeItem(refreshTokenLocalStorageKey);
    } else {
        window.localStorage.setItem(refreshTokenLocalStorageKey, refreshToken);
    }

    store.commit(SET_AUTH, {
        accessToken: accessToken,
        expiration: tokenExpiration,
        refreshToken: refreshToken,
        isAdmin: isAdmin,
        user: {
            id: id,
            name: name
        }
    });
}

function onAccessTokenExpired() {
    store.commit(SET_AUTH, {
        accessToken: undefined,
        expiration: undefined,
        isAdmin: false,
        user: {
            id: undefined,
            name: undefined
        }
    });

    // Toast Notification alerting the user they have been logged out?
    // Or maybe we add a param to the login route which includes the message?

    if (router.currentRoute.name === 'login') return;
    router.push({ name: 'login' });
}

async function handleLoginCallback(code) {
    if (store.state.authentication.authenticated)
        return true;

    const token = await obtainInitialTokens(code);
    if (!token) return false;

    const decodedAccessToken = jwt_decode(token.accessToken);

    storeAuthentication(token.accessToken, decodedAccessToken.exp * 1000, token.refreshToken, decodedAccessToken.is_admin == '1', decodedAccessToken.nameid, decodedAccessToken.name)
    return true;
}

async function obtainInitialTokens(code) {
    try {
        let response = await httpService.post('auth/token?code=' + code);
        return response.data?.data;
    } catch (e) {
        console.error(e);
        return undefined;
    }
}

async function refreshAccessToken(refreshToken) {
    const response = await httpService.post('auth/token/refresh?refresh-token=' + refreshToken);
    const responseData = response.data.data;
    if (!responseData)
        throw 'failed to refresh access token, response data was missing'
    let decodedAccessToken = jwt_decode(responseData.accessToken);
    const tokenExpiration = decodedAccessToken.exp * 1000;
    if (tokenExpiration <= Date.now()) {
        onAccessTokenExpired()
        return;
    }
    storeAuthentication(responseData.accessToken, tokenExpiration, responseData.refreshToken, decodedAccessToken.is_admin == '1', decodedAccessToken.nameid, decodedAccessToken.name);
}

async function revokeRefreshToken(refreshToken) {
    try {
        await httpService.post('auth/token/revoke?refresh-token=' + refreshToken);
    } catch {

    }
}

function login() {
    if (store.state.authentication.authenticated) {
        console.warn('attempting to login when already authenticated');
        return;
    }

    window.location.href =
        httpService.baseUrl +
        "auth/login?callbackUrl=" +
        window.location.origin + "/login" +
        "&errorUrl=" +
        window.location.origin + "/login";
}

async function logout() {
    if (store.state.authentication.refreshToken) {
        await revokeRefreshToken(store.state.authentication.refreshToken);
    }

    store.commit(SET_AUTH, {
        accessToken: undefined,
        expiresAt: undefined,
        refreshToken: undefined,
        isAdmin: false,
        user: {
            id: undefined,
            name: undefined
        }
    });

    window.localStorage.removeItem(accessTokenLocalStorageKey);
    window.localStorage.removeItem(refreshTokenLocalStorageKey);
}

export default {
    login,
    logout,
    handleLoginCallback
}

initialize();