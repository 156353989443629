<template>
  <b-col lg="8" class="mx-auto">
    <b-alert variant="success" :show="savedAlert" dismissible>Changes saved!</b-alert>
    <b-alert variant="danger" :show="!!error">An error occurred: {{ error }}</b-alert>

    <b-form @reset="onReset" @submit="onSave">
      <b-row>
        <b-col md="4">
          <b-form-group label="Display Name:" label-for="displayName" description="This is used only on the webpanel, this value will not change the game server name">
            <b-form-input id="displayName" v-model="form.displayName" :state="displayNameValid" required></b-form-input>
            <b-form-invalid-feedback :state="displayNameValid">A display name is required.</b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group label="Custom uMod Url:" label-for="customUmodUrl" description="An empty value will result in the official release being used">
            <b-form-input id="customUmodUrl" v-model="form.customUmodUrl"></b-form-input>
            <b-form-invalid-feedback :state="customUmodUrlValid">Invalid URL</b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group label="Cpu Affinity:" label-for="cpuAffinity">
            <b-form-input id="cpuAffinity" v-model="form.cpuAffinity" type="number" required min="0" max="65535"></b-form-input>
            <b-form-invalid-feedback :state="cpuAffinityValid">Invalid CPU affinity, CPU affinity should be a number between 0 and 65535</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="4">
          <b-form-group label="IP Address:" label-for="serverIp">
            <b-form-input id="serverIp" v-model="form.serverIp" required></b-form-input>
            <b-form-invalid-feedback :state="serverIpValid">Invalid IP Address</b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group label="Server Port:" label-for="serverPort">
            <b-form-input id="serverPort" v-model="form.serverPort" type="number" required min="1" max="65535"></b-form-input>
            <b-form-invalid-feedback :state="serverPortValid">Invalid server port, ports should be a number between 1 and 65535</b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group label="Query Port:" label-for="queryPort">
            <b-form-input id="queryPort" v-model="form.queryPort" type="number" required min="1" max="65535"></b-form-input>
            <b-form-invalid-feedback :state="queryPortValid">Invalid query port, ports should be a number between 1 and 65535</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="4">
          <b-form-group label="Rcon IP:" label-for="rconIp">
            <b-form-input id="rconIp" v-model="form.rconIp" required></b-form-input>
            <b-form-invalid-feedback :state="rconIpValid">Invalid IP Address</b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group label="Rcon Port:" label-for="rconPort">
            <b-form-input id="rconPort" v-model="form.rconPort" type="number" required min="1" max="65535"></b-form-input>
            <b-form-invalid-feedback :state="rconPortValid">Invalid server port, ports should be a number between 1 and 65535</b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group label="Rcon Password:" label-for="rconPassword">
            <b-form-input id="rconPassword" v-model="form.rconPassword" required></b-form-input>
            <b-form-invalid-feedback :state="rconPasswordValid">RCON Password cannot be empty</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="4">
          <b-form-group label="Rust+ Listen IP:" label-for="appListenIp">
            <b-form-input id="appListenIp" v-model="form.appListenIp" required></b-form-input>
            <b-form-invalid-feedback :state="appListenIpValid">Invalid IP Address</b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group label="Rust+ Public IP:" label-for="appPublicIp">
            <b-form-input id="appPublicIp" v-model="form.appPublicIp" required></b-form-input>
            <b-form-invalid-feedback :state="appPublicIpValid">Invalid IP Address</b-form-invalid-feedback>
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group label="Rust+ Public IP:" label-for="appPort">
            <b-form-input id="appPort" v-model="form.appPort" type="number" required min="1" max="65535"></b-form-input>
            <b-form-invalid-feedback :state="appPortValid">Invalid server port, ports should be a number between 1 and 65535</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Map Url:" label-for="mapUrl">
            <b-form-input id="mapUrl" v-model="form.mapUrl"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="4">
          <b-form-group label="Seed:" label-for="seed">
            <b-form-input id="seed" v-model="form.seed" type="number" required min="-2147483648" max="2147483648"></b-form-input>
            <b-form-invalid-feedback :state="appSeedValid">Invalid seed, seeds should be a number between -2147483648 and 2147483648</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="World Size:" label-for="worldSize">
            <b-form-input id="worldSize" v-model="form.worldSize" type="number" required min="-2147483648" max="2147483648"></b-form-input>
            <b-form-invalid-feedback :state="appWorldSizeValid">Invalid world size, world sizes should be a number between -2147483648 and 2147483648</b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Branch:" label-for="branch" description="Leave this empty to use the public branch, otherwise input staging for staging branch">
            <b-form-input id="branch" v-model="form.branch"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group label="Command Line Arguments:" label-for="arguments">
        <b-form-textarea id="arguments" v-model="form.arguments"></b-form-textarea>
      </b-form-group>

      <b-button-group class="float-right">
        <b-button type="reset" variant="danger" :disabled="saving">Reset</b-button>
        <b-button type="submit" variant="primary" :disabled="saving">Save</b-button>
      </b-button-group>
    </b-form>
  </b-col>
</template>

<script>
import { mapState } from "vuex";

const ipAddresRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
const urlRegex = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;

export default {
  name: "ServerSettings",
  created() {
    this.setFormDefaults();
  },
  beforeDestroy() {
    if (this.savedAlertTimeout) clearTimeout(this.savedAlertTimeout);
  },
  data(that) {
    return {
      serverId: that.$route.params.serverId,
      hostAlias: that.$route.params.hostAlias,
      saving: false,
      savedAlert: false,
      error: undefined,
      form: {
        displayName: undefined,
        serverIp: undefined,
        serverPort: undefined,
        queryPort: undefined,
        cpuAffinity: undefined,
        rconIp: undefined,
        rconPort: undefined,
        rconPassword: undefined,
        appListenIp: undefined,
        appPublicIp: undefined,
        appPort: undefined,
        arguments: undefined,
        customUmodUrl: undefined,
        mapUrl: undefined,
        seed: undefined,
        worldSize: undefined,
        branch: undefined
      },
    };
  },
  methods: {
    onReset(event) {
      event.preventDefault();
      this.setFormDefaults();
    },
    onSave(event) {
      event.preventDefault();
      if (this.saving) return;
      this.saving = true;
      this.$services.api
        .updateServer(this.hostAlias, this.serverId, this.form)
        .then(() => {
          this.error = undefined;
          this.savedAlert = true;
          if (this.savedAlertTimeout) clearTimeout(this.savedAlertTimeout);
          this.savedAlertTimeout = setTimeout(() => (this.savedAlert = false), 5000);
        })
        .catch((e) => {
          this.error = e;
        })
        .finally(() => {
          this.saving = false;
        });
    },
    setFormDefaults() {
      this.form.displayName = this.gameServer.displayName;
      this.form.serverIp = this.gameServer.serverIp;
      this.form.serverPort = this.gameServer.serverPort;
      this.form.queryPort = this.gameServer.queryPort;
      this.form.cpuAffinity = this.gameServer.cpuAffinity;
      this.form.rconIp = this.gameServer.rconIp;
      this.form.rconPort = this.gameServer.rconPort;
      this.form.rconPassword = this.gameServer.rconPassword;
      this.form.appListenIp = this.gameServer.appListenIp;
      this.form.appPublicIp = this.gameServer.appPublicIp;
      this.form.appPort = this.gameServer.appPort;
      this.form.arguments = this.gameServer.arguments;
      this.form.customUmodUrl = this.gameServer.customUmodUrl;
      this.form.mapUrl = this.gameServer.mapUrl;
      this.form.seed = this.gameServer.seed;
      this.form.worldSize = this.gameServer.worldSize;
      this.form.branch = this.gameServer.branch;
    },
    ValidateIPaddress(ipaddress) {
      return ipAddresRegex.test(ipaddress);
    },
  },
  computed: {
    ...mapState({
      gameServer(state) {
        if (!state.hosts) return undefined;
        const host = state.hosts[this.hostAlias];
        if (!host) return undefined;
        const gameServers = host.gameServers?.filter((x) => x.id === +this.serverId);
        if (!gameServers) return undefined;
        return gameServers[0];
      },
    }),
    displayNameValid() {
      if (!this.form.displayName) return false;
      if (this.form.displayName.trim().length <= 0) return false;
      return undefined;
    },
    serverIpValid() {
      return ipAddresRegex.test(this.form.serverIp);
    },
    serverPortValid() {
      const port = +this.form.serverPort;
      if (Number.isNaN(port)) return false;
      return port >= 1 && port <= 65535;
    },
    queryPortValid() {
      const port = +this.form.queryPort;
      if (Number.isNaN(port)) return false;
      return port >= 1 && port <= 65535;
    },
    rconIpValid() {
      return ipAddresRegex.test(this.form.rconIp);
    },
    rconPortValid() {
      const port = +this.form.rconPort;
      if (Number.isNaN(port)) return false;
      return port >= 1 && port <= 65535;
    },
    rconPasswordValid() {
      if (!this.form.rconPassword) return false;
      if (this.form.rconPassword.trim().length <= 0) return false;
      return undefined;
    },
    appListenIpValid() {
      return ipAddresRegex.test(this.form.appListenIp);
    },
    appPublicIpValid() {
      return ipAddresRegex.test(this.form.appPublicIp);
    },
    appPortValid() {
      const port = +this.form.appPort;
      if (Number.isNaN(port)) return false;
      return port >= 1 && port <= 65535;
    },
    appSeedValid() {
      const seed = +this.form.seed;
      if (Number.isNaN(seed)) return false;
      return true;
    },
    appWorldSizeValid() {
      const worldSize = +this.form.worldSize;
      if (Number.isNaN(worldSize)) return false;
      return true;
    },
    customUmodUrlValid() {
      if (!this.form.customUmodUrl) return true;
      if (this.form.customUmodUrl.trim().length <= 0) return true;
      return urlRegex.test(this.form.customUmodUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>