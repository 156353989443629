<template>
  <div>
    <div class="info-panel p-2 mb-4">
      <h6>
        State:
        <span class="float-right">{{ gameServer.state }}</span>
      </h6>
      <h6>
        IP:
        <span class="float-right">{{ gameServer.serverIp }}</span>
      </h6>
      <h6>
        Server Port:
        <span class="float-right">{{ gameServer.serverPort }}</span>
      </h6>
      <h6>
        Query Port:
        <span class="float-right">{{ gameServer.queryPort }}</span>
      </h6>
      <h6>
        Console:
        <span class="float-right">{{ gameServer.rconConnected ? "Connected" : "Not Connected" }}</span>
      </h6>
      <b-btn-group class="w-100">
        <b-button style="min-width: 33.333%" @click="startServer" :disabled="isGameServerStarted" variant="primary">
          <template v-if="!starting">Start</template>
          <template v-else><b-spinner small></b-spinner></template>
        </b-button>
        <b-button style="min-width: 33.333%" @click="stopServer" :disabled="isGameServerStopped" variant="warning">
          <template v-if="!stopping">Stop</template>
          <template v-else><b-spinner small></b-spinner></template>
        </b-button>
        <b-button style="min-width: 33.333%" @click="killServer" :disabled="isGameServerStopped" variant="danger">
          <template v-if="!killing">Kill</template>
          <template v-else><b-spinner small></b-spinner></template>
        </b-button>
      </b-btn-group>
    </div>

    <div class="info-panel p-2 mb-4">
      <h6>FTP Details</h6>
      <span>Host:</span><span style="line-break: anywhere;" class="float-right">{{ ftpHost }}</span>
      <br />
      <span>Port:</span><span style="line-break: anywhere;" class="float-right">{{ ftpPort }}</span>
      <br />
      <span>Encryption:</span><span style="line-break: anywhere;" class="float-right">Explicit FTP over TLS</span>
      <br />
      <span>Username:</span><span style="line-break: anywhere;" class="float-right">{{ gameServer.id }}.&lt;your_ftp_username&gt;</span>
    </div>

    <div class="info-panel p-2 mb-4">
      <h6>
        Players:
        <span v-if="gameServer.queuedPlayers > 0" class="float-right">{{ gameServer.connectedPlayers }}/{{
          gameServer.maxPlayers }} ({{ gameServer.queuedPlayers }})</span>
        <span v-else class="float-right">{{ gameServer.connectedPlayers }}/{{ gameServer.maxPlayers }}</span>
      </h6>

      <h6>
        FPS:
        <span class="float-right">{{ gameServer.fps }}</span>
      </h6>

      <h6>
        Entities:
        <span class="float-right">{{ gameServer.entities }}</span>
      </h6>

      <h6>
        Uptime:
        <span class="float-right">{{ gameServer.uptime }}</span>
      </h6>

      <h6>
        Last Heartbeat:
        <span class="float-right">{{ lastHeartbeatString }}</span>
      </h6>
    </div>

    <div class="info-panel p-2">
      <h6>Auto Start</h6>
      <yes-no-button-group paramName="autoStart" :paramState="gameServer.autoStart" :isLoading="toggleStates['autoStart']"
        :onClick="toggleSetting" />

      <h6>Keep Alive</h6>
      <yes-no-button-group paramName="keepAlive" :paramState="gameServer.keepAlive" :isLoading="toggleStates['keepAlive']"
        :onClick="toggleSetting" />

      <h6>Auto Update Rust</h6>
      <yes-no-button-group paramName="updateRust" :paramState="gameServer.updateRust"
        :isLoading="toggleStates['updateRust']" :onClick="toggleSetting" />

      <h6>Auto Update uMod</h6>
      <yes-no-button-group paramName="updateUmod" :paramState="gameServer.updateUmod"
        :isLoading="toggleStates['updateUmod']" :onClick="toggleSetting" />
    </div>
  </div>
</template>

<script>
import YesNoButtonGroup from "../YesNoButtonGroup.vue";
export default {
  name: "ServerQuickActions",
  props: ["gameServer", "hostAlias", "ftpHost", "ftpPort"],
  components: { YesNoButtonGroup },
  data: function () {
    return {
      starting: false,
      stopping: false,
      killing: false,
      toggleStates: {},
    };
  },
  computed: {
    isGameServerStopped() {
      if (!this.gameServer) return undefined;

      return this.gameServer.state === "Stopped" || this.gameServer.state === "Errored";
    },
    isGameServerStarted() {
      return !this.isGameServerStopped;
    },
    lastHeartbeatString() {
      if (!this.gameServer.lastHeartbeat) return "NIL";
      try {
        return new Date(Date.parse(this.gameServer.lastHeartbeat)).toLocaleString();
      } catch {
        return "ERROR";
      }
    },
  },
  methods: {
    startServer(e) {
      const that = this;
      this.starting = true;
      this.$services.api
        .startServer(this.hostAlias, this.gameServer.id)
        .catch((err) => console.error("start server failure", err))
        .finally(() => {
          that.starting = false;
        });
    },
    stopServer(e) {
      const that = this;
      this.stopping = true;
      this.$services.api
        .stopServer(this.hostAlias, this.gameServer.id)
        .catch((err) => console.error("stop server failure", err))
        .finally(() => {
          that.stopping = false;
        });
    },
    killServer(e) {
      const that = this;
      this.killing = true;
      this.$services.api
        .killServer(this.hostAlias, this.gameServer.id)
        .catch((err) => console.error("kill server failure", err))
        .finally(() => {
          that.killing = false;
        });
    },
    toggleSetting(setting, state) {
      const that = this;
      this.toggleStates[setting] = true;
      this.$forceUpdate();
      const packet = {
        [setting]: state,
      };
      this.$services.api
        .updateServer(this.hostAlias, this.gameServer.id, packet)
        .catch((err) => {
          that.error = err;
        })
        .finally(() => {
          that.toggleStates[setting] = false;
          that.$forceUpdate();
        });
    },
  },
};
</script>

<style scoped>
.btn {
  user-select: none;
  box-shadow: none !important;
}

.info-panel {
  border: 0.15rem solid rgba(77, 77, 77, 0.1);
  border-radius: 0.25rem;
  background: rgb(42, 42, 42);
}
</style>