export function initialized(state) {
    const keys = Object.keys(state.initialization)
    for (let key of keys) {
        const value = state.initialization[key];
        if (typeof (value) !== 'boolean') continue;
        if (!value) return false;
    }
    return true;
}

export function authenticated(state) {
    if (!state.authentication.accessToken)
        return false;
    if (state.authentication.expiration < Date.now())
        return false;
    return true;
}

export function admin(state) {
    if (!state.authentication.accessToken)
        return false;
    if (state.authentication.expiration < Date.now())
        return false;
    if (!state.authentication.isAdmin)
        return false;
    return true;
}