<template>
  <b-col lg="12" class="mx-auto d-flex flex-column">
    <b-card style="background: transparent; border: 1px solid rgba(255, 255, 255, 0.1)">
      <b-card-title>Pre Start Downloads</b-card-title>
      <server-pre-start-download-tasks />
    </b-card>
  </b-col>
</template>

<script>
import ServerPreStartDownloadTasks from "./ServerTasks/ServerPreStartDownloadTasks.vue";

export default {
  components: { ServerPreStartDownloadTasks },
  name: "ServerTasks",
};
</script>