<template>
  <transition name="fade" mode="out-in">
    <InitializingView v-if="!$store.getters.initialized" />
    <router-view v-else />
  </transition>
</template>

<script>
import InitializingView from "./views/InitializingView";

export default {
  name: "App",
  components: {
    InitializingView,
  },
};
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 75ms;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
