import store from '../../store';
import { initialized } from '../../store/getters';

export default async (to, from, next) => {
    if (!initialized(store.state)) {
        let unsubscribe;
        unsubscribe = store.watch(initialized, initialized => {
            if (!initialized) return;
            if (unsubscribe && typeof (unsubscribe) === 'function') unsubscribe();
            next();
        })
        return
    }

    return next();
}