import { Route } from 'v-route-helper';
import GlobalLayout from '../layouts/GlobalLayout.vue';
import HomeView from '../views/HomeView.vue';
import ServerInfoView from '../views/ServerInfoView.vue';
import HostInfoView from '../views/HostInfoView.vue';
import LoginView from '../views/LoginView.vue';
import NotFoundView from '../views/NotFoundView.vue';
import ForbiddenView from '../views/ForbiddenView.vue';

import ServerOverview from "../components/server-info/sub-views/ServerOverview.vue";
import ServerTasks from "../components/server-info/sub-views/ServerTasks.vue";
import ServerSchedules from "../components/server-info/sub-views/ServerSchedules.vue";
import ServerSettings from "../components/server-info/sub-views/ServerSettings.vue";

import HostServers from "../components/host-info/sub-views/HostServers.vue";
import HostFtpUsers from "../components/host-info/sub-views/HostFtpUsers.vue";
import HostSettings from "../components/host-info/sub-views/HostSettings.vue";


const AuthenticatedRoute = (...e) => Route(...e).meta('auth', true);
const AdminRoute = (...e) => Route(...e).meta('auth', true).meta('admin', true);
const NonAuthenticatedRoute = (...e) => Route(...e).meta('auth', false);

export default [
  NonAuthenticatedRoute('/login', LoginView).name('login'),
  Route('/forbidden', ForbiddenView).name('forbidden'),

  AuthenticatedRoute('/', GlobalLayout).group(() => [
    AuthenticatedRoute('', HomeView).name('home'),
    AuthenticatedRoute('/:hostAlias', HostInfoView).group(() => [
      AuthenticatedRoute('', HostServers).name('hosts.info.servers'),
      AdminRoute('ftp-users', HostFtpUsers).name('hosts.info.ftp-users'),
      AdminRoute('settings', HostSettings).name('hosts.info.settings'),
    ]),
    AuthenticatedRoute('/:hostAlias/servers/:serverId', ServerInfoView).group(() => [
      AuthenticatedRoute('', ServerOverview).name("servers.info.overview"),
      AuthenticatedRoute('tasks', ServerTasks).name('servers.info.tasks'),
      AuthenticatedRoute('schedules', ServerSchedules).name('servers.info.schedules'),
      AuthenticatedRoute('settings', ServerSettings).name('servers.info.settings'),  
    ])
  ]),

  Route('**', NotFoundView).name('not-found'),
];
