import Vue from 'vue'
import VueRouter from 'vue-router';
import VRouteHelper from 'v-route-helper';

import guards from './guards'
import routes from './routes'

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL
});

for (let guard of guards) {
    router.beforeEach(guard);
}

Vue.use(VRouteHelper, { router, routes });

export default router;