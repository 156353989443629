<template>
  <div class="flex-grow-1 d-flex flex-column">
    <b-alert v-if="error" variant="danger">{{ error }}</b-alert>
    <div v-if="host" class="flex-grow-1 d-flex flex-column">
      <div class="mb-2 d-flex flex-wrap justify-content-between">
        <div class="flex-grow-1 heading-title d-flex flex-column justify-content-end">
          <router-link tag="h4" style="cursor: pointer" :to="baseRoute" title="Servers" exact>
            {{ hostAlias }}
          </router-link>
        </div>
        <div class="d-flex align-self-end">
          <router-link v-for="route in routes" :key="route.title" :to="baseRoute + route.route" class="route-item"
            :title="route.title" active-class="active" exact>
            <div class="route-item-content">
              <span class="route-item-icon"> </span>
              <span class="route-item-name">{{ route.title }}</span>
            </div>
          </router-link>
        </div>
      </div>
      <b-row class="mb-2 flex-grow-1 d-flex flex-row">
        <router-view />
      </b-row>
    </div>
    <div v-else class="flex-grow m-auto text-center">
      <h1>Loading Host...</h1>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "HostInfoView",
  created() {
    const that = this;
    if (!this.$store.state.hosts) {
      this.$services.api
        .getHosts()
        .catch((err) => {
          console.error("failed to get hosts", err);
          that.error = "Failed to retrieve hosts from api";
        });
    }
  },
  data: (that) => {
    return {
      hostAlias: that.$route.params.hostAlias,
      error: undefined,
      allRoutes: [
        { title: "Servers", icon: "", admin: false, route: "" },
        { title: "FTP Users", icon: "", admin: true, route: "ftp-users" },
        { title: "Settings", icon: "", admin: true, route: "settings" },
      ],
    };
  },
  computed: {
    ...mapState({
      host(state) {
        return state.hosts[this.$route.params.hostAlias];
      }
    }),
    gameServers: (that) => {
      let gameServers = [];
      if (!that.host) return gameServers;
      const host = that.host[alias];
      if (!host.gameServers) return gameServers;
      gameServers = host.gameServers;
      gameServers = gameServers.sort((a, b) => a.displayName.localeCompare(b.displayName));
      return gameServers;
    },
    routes: (that) => {
      const isAdmin = that.$store.getters.admin
      return that.allRoutes.filter(x => !x.admin || isAdmin)
    },
    baseRoute: (that) => `/${that.$route.params.hostAlias}/`,
  }
};
</script>

<style lang="scss" scoped>
.heading-title {
  border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
}

.route-item {
  display: block;
  border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
  padding: 1px 1px 0px 1px;
  text-decoration: none;
  color: inherit;

  .route-item-content {
    padding: 1rem;
  }

  &.active {
    border: 1px solid rgba($color: #fff, $alpha: 0.1);
    border-bottom: none;
    border-top-left-radius: 0.25em;
    border-top-right-radius: 0.25em;
    padding: 0px 0px 1px 0px;
  }
}
</style>
