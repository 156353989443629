<template>
  <b-row class="host-card px-2 mb-2">
    <b-col class="host-card-heading my-2 text-truncate">
      <router-link tag="span" style="cursor: pointer"  :to="'/' + host.alias">{{ host.alias }}</router-link>
    </b-col>
    <b-col xs="1" class="align-middle my-2 text-right text-muted">
      <span v-if="host.updating">Updating</span>
      <span v-else-if="host.updateError">{{ host.updateError }}</span>
      <span v-else-if="host.lastUpdated">
        Updated: {{ localUpdatedString(host.lastUpdated) }}
      </span>
    </b-col>
    <game-server-card v-for="gameServer in host.gameServers" :key="host.alias + '-' + gameServer.id"
      :gameServer="gameServer" :hostAlias="host.alias" />
  </b-row>
</template>

<script>
import GameServerCard from "./GameServerCard.vue";
export default {
  components: { GameServerCard },
  name: "HostCard",
  props: ["host"],
  methods: {
    localUpdatedString(epoch) {
      let date = new Date(epoch);
      return date.toLocaleString();
    },
  },
};
</script>

<style lang="scss" scoped>
.host-card {
  border: 0.1rem solid rgba(0, 0, 0, 0);
  border-radius: 0.25rem;
  background: rgb(42, 42, 42);
}
</style>