<template>
  <div class="forbidden-container">
    <h1>403 Forbidden</h1>
    <p>You do not have access to this resource</p>
    <b-button-group>
      <b-button v-on:click="goBack" variant="success">Back</b-button>
      <b-button to="/" variant="danger">Home</b-button>
  </b-button-group>
  </div>
</template>

<script>
export default {
  name: "Forbidden",
  methods:{
    goBack() {
      this.$router.go(-2);
    }
  }
};
</script>

<style scoped>
.forbidden-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>