<template>
  <div class="notfound-container">
    <h1>Not Found</h1>
    <p>The resource you were looking for cannot be found</p>
    <b-button-group>
      <b-button v-on:click="goBack" variant="success">Back</b-button>
      <b-button to="/" variant="danger">Home</b-button>
  </b-button-group>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  methods:{
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style>
.notfound-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

</style>