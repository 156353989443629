import store from '../../store';

export default async (to, from, next) => {
    const isAdmin = store.getters.admin;
    const requiresAdmin = to.meta.admin;
    if (typeof (requiresAdmin) == 'boolean') {
        if (requiresAdmin && !isAdmin)
            return next({ name: 'forbidden' });
    }

    return next();
}