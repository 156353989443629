export const AUTH_READY = 'AUTH_READY';
export const API_READY = 'API_READY';
export const SET_AUTH = 'SET_AUTH';
export const SET_HOST = 'SET_HOST';
export const SET_TIMEDEVENTS = 'SET_TIMEDEVENTS';
export const SET_TIMEDEVENT = 'SET_TIMEDEVENT';
export const SET_TIMEDEVENTACTION = 'SET_TIMEDEVENTACTION';
export const REMOVE_TIMEDEVENTACTION = 'REMOVE_TIMEDEVENTACTION';
export const REMOVE_TIMEDEVENT = 'REMOVE_TIMEDEVENT';
export const SET_PRESTARTDOWNLOADTASKS = "SET_PRESTARTDOWNLOADTASKS";
export const SET_PRESTARTDOWNLOADTASK = "SET_PRESTARTDOWNLOADTASK";
export const REMOVE_PRESTARTDOWNLOADTASK = "REMOVE_PRESTARTDOWNLOADTASK";
